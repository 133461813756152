import React, { useEffect, useState } from "react";
import classNames from "classnames";

import { CloseIcon, InformationCircleIcon } from "@shared/components/icons";
import { LockFeature } from "@shared/components/lock-feature/lock-feature";
import { useUpload } from "@shared/hooks/use-upload";
import { SublyPlan } from "@shared/interfaces/billing";
import { Fieldset } from "@shared/primitives/fieldset";
import { FileButton } from "@shared/primitives/file-input";
import { ToolTip } from "@shared/primitives/tooltip";
import { uploadQueueStore } from "@shared/state/upload-queue";
import { readFileAsTranscription } from "@shared/utils/file";
import { pluralize } from "@shared/utils/strings";
import { countTranscriptionErrors } from "@shared/utils/transcription";

import { sanitizeTranscription } from "@getsubly/common/dist/assParser";

interface SrtUploaderProps {
  className?: string;
}
export const SrtUploader: React.FC<SrtUploaderProps> = ({ className }) => {
  const [srtFile, setSrtFile] = useState<File>();
  const [error, setError] = useState<string>("");
  const [warning, setWarning] = useState<string>();
  const { totalCount } = useUpload();

  useEffect(() => {
    if (totalCount > 1) {
      setSrtFile(undefined);
      uploadQueueStore.setTranscription(undefined);
    }
  }, [totalCount]);

  const handleSrtSelection = async (file: File) => {
    setWarning("");
    setError("");
    setSrtFile(file);

    const srtData = await readFileAsTranscription(file);

    const srt = sanitizeTranscription(srtData);

    uploadQueueStore.setTranscription(srt);

    if (!srt) {
      setError("Invalid srt file");
    } else {
      const errorCount = countTranscriptionErrors(srt);

      if (errorCount) {
        setWarning(`Transcript has ${errorCount} ${pluralize(errorCount, "issue")}.`);
      }
    }
  };

  return (
    <Fieldset title={<UploadSrtTitle />} className={className}>
      <LockFeature minPermission={SublyPlan.Business}>
        <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-1">
          <ToolTip
            text={`SRT file can only be added to 1 file. ${
              totalCount > 1 ? `Please remove ${totalCount - 1} out of ${totalCount} of the selected files.` : ""
            }`}
          >
            <FileButton
              variant="secondary"
              accept=".srt,.vtt"
              className="tw-mx-0 tw-block"
              onChange={handleSrtSelection}
              disabled={totalCount > 1}
            >
              Upload file
            </FileButton>
          </ToolTip>

          {srtFile && (
            <>
              <label className="tw-mb-0 tw-w-1/4 tw-text-ellipsis">{srtFile.name}</label>

              <label
                className={classNames("tw-mb-0 tw-ml-auto tw-text-brand-1", {
                  "tw-text-aux-2-500": error,
                  "tw-text-warning-500": !error && warning
                })}
              >
                {error || warning}
              </label>

              <CloseIcon
                className="close tw-cursor tw-ml-[14px] tw-h-5 tw-w-5"
                onClick={() => {
                  setSrtFile(undefined);
                  uploadQueueStore.setTranscription(undefined);
                }}
              />
            </>
          )}
        </div>
      </LockFeature>
    </Fieldset>
  );
};

const UploadSrtTitle: React.FC = () => {
  return (
    <div className="tw-inline-flex">
      Upload SRT / VTT{" "}
      <ToolTip
        text="SRT / VTT files can be added when uploading a single video. Videos will not be transcribed when SRT / VTT files are added.
          Note: You will be unable to upload an SRT / VTT file if you select multiple files or bulk upload URLs."
      >
        <InformationCircleIcon className="tw-ml-1 tw-cursor-pointer tw-stroke-neutral-500" width={20} height={20} />
      </ToolTip>
    </div>
  );
};
