import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { v4 } from "uuid";

import { Checkbox } from "@shared/primitives/checkbox/checkbox";

import { RiArrowDownLine, RiArrowUpLine } from "@remixicon/react";

import { ToolTip } from "../tooltip";

import { TableColumn, TableItem, TableProvider, useTableContext } from "./table.context";

type SortDirection = "asc" | "desc";
type ColumnWidth = number | string | undefined | null;

const parseColumnWidth = (width: ColumnWidth): string => {
  if (width === undefined || width === null) return "1fr";
  return typeof width === "number" ? `${width}px` : width;
};

interface ContainerProps {
  columns: TableColumn[];
  items: TableItem[];
  children: React.ReactNode;
  className?: string;
}
const Container: React.FC<ContainerProps> = ({ columns, items, children, className }) => {
  const [hasOverflow, setHasOverflow] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current) {
        const hasHorizontalOverflow = containerRef.current.scrollWidth > containerRef.current.clientWidth;
        setHasOverflow(hasHorizontalOverflow);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, []);

  const columnWidthVar = columns.map((column) => parseColumnWidth(column.width)).join(" ");

  return (
    <TableProvider columns={columns} items={items}>
      <div
        ref={containerRef}
        className={classNames(className, "tw-grid tw-auto-rows-auto tw-rounded-[8px] tw-border tw-border-neutral-200")}
        style={{ gridTemplateColumns: columnWidthVar } as React.CSSProperties}
        data-has-overflow={hasOverflow}
      >
        {children}
      </div>
    </TableProvider>
  );
};

interface HeaderProps {
  children: (column: TableColumn[]) => React.ReactElement[];
  className?: string;
}
const Header: React.FC<HeaderProps> = ({ children, className }) => {
  const { columns } = useTableContext();

  return (
    <div className={classNames("tw-col-span-full tw-grid tw-grid-cols-subgrid", className)}>{children(columns)}</div>
  );
};

interface HeaderCellProps
  extends SortingProps,
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  sortable?: boolean;
  invertSort?: boolean;
  checkable?: boolean;
  checkboxChecked?: boolean;
  checkboxIndeterminate?: boolean;
  checkboxDisabled?: boolean;
  columnId: number | string;
  tooltip?: string;
  onClickSort?: () => void;
  onChangeCheckbox?: (checked: boolean) => void;
}
const HeaderCell: React.FC<HeaderCellProps> = ({
  sortable = false,
  invertSort = false,
  checkable = false,
  checkboxChecked = false,
  checkboxIndeterminate = false,
  checkboxDisabled = false,
  isSorting = false,
  onClickSort,
  sortDirection,
  children,
  className,
  columnId,
  tooltip,
  onClick,
  onChangeCheckbox,
  ...props
}) => {
  const { columns } = useTableContext();
  const column = columns.find((column) => column.id === columnId);
  const isSticky = column?.sticky === "right";

  const cellUniqueId = "table-header-cell-" + v4();

  return (
    <div
      className={classNames(
        "tw-flex tw-h-12 tw-items-center tw-gap-1 tw-bg-[inherit] tw-p-2 tw-text-sm tw-text-neutral-700",
        {
          "tw-group tw-cursor-pointer": sortable,
          "tw-sticky tw-right-0 tw-z-10 before:tw-absolute before:tw-inset-y-0 before:-tw-left-1.5 before:tw-w-1.5 before:tw-bg-gradient-to-l before:tw-from-black/[0.07] before:tw-to-transparent before:tw-opacity-0 before:tw-transition-opacity [div[data-has-overflow=true]_&]:before:tw-opacity-100":
            isSticky
        },
        className
      )}
      onClick={(e) => {
        if (sortable) onClickSort?.();
        onClick?.(e);
      }}
      {...props}
      data-tooltip-id={cellUniqueId}
    >
      {checkable && (
        <div
          className="tw--my-1.5 tw--ml-1.5 tw-mr-0.5 tw-inline-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center"
          data-table-checkbox
        >
          <Checkbox
            checked={checkboxChecked}
            indeterminate={checkboxIndeterminate}
            disabled={checkboxDisabled}
            onChange={onChangeCheckbox}
            stopPropagation
          />
        </div>
      )}
      {children}
      {sortable && <Sorting isSorting={isSorting} sortDirection={sortDirection} invertSort={invertSort} />}
      {tooltip && (
        <ToolTip anchorId={cellUniqueId} text={tooltip} place="top" className="tw-w-32 tw-whitespace-pre-line" />
      )}
    </div>
  );
};

interface SortingProps {
  isSorting?: boolean;
  sortDirection?: SortDirection;
  invertSort?: boolean;
}
const Sorting: React.FC<SortingProps> = ({ isSorting, sortDirection, invertSort = false }) => {
  if (!isSorting) {
    return <RiArrowDownLine className="tw-h-4 tw-w-4 tw-opacity-0 group-hover:tw-opacity-50" />;
  }

  const effectiveDirection = invertSort ? (sortDirection === "asc" ? "desc" : "asc") : sortDirection;

  const Icon = effectiveDirection === "asc" ? RiArrowUpLine : RiArrowDownLine;
  return <Icon className="tw-h-4 tw-w-4 group-hover:tw-opacity-50" />;
};

interface RowProps {
  children: React.ReactNode;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  hoverable?: boolean;
}
const Row: React.FC<RowProps> = ({ children, className, onClick, hoverable = false }) => {
  return (
    <div
      className={classNames(
        "tw-col-span-full tw-grid tw-grid-cols-subgrid tw-bg-white tw-text-neutral-600 tw-transition-colors",
        { "hover:tw-bg-neutral-50": hoverable, "tw-cursor-pointer": onClick },
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

type CellProps = {
  children?: React.ReactNode;
  columnId: number | string;
  checkable?: boolean;
  checkboxChecked?: boolean;
  checkboxIndeterminate?: boolean;
  checkboxDisabled?: boolean;
  onCheckboxChange?: (checked: boolean) => void;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
const Cell: React.FC<CellProps> = ({
  className,
  columnId,
  children,
  checkable = false,
  checkboxChecked = false,
  checkboxIndeterminate = false,
  checkboxDisabled = false,
  onCheckboxChange,
  ...props
}) => {
  const { columns } = useTableContext();
  const column = columns.find((column) => column.id === columnId);
  const isSticky = column?.sticky === "right";

  return (
    <div
      className={classNames(
        "tw-relative tw-flex tw-items-center tw-gap-1 tw-border-t tw-border-neutral-200 tw-bg-[inherit] tw-p-2 tw-text-sm",
        {
          "tw-sticky tw-right-0 tw-z-10 before:tw-absolute before:tw-inset-y-0 before:-tw-left-1.5 before:tw-w-1.5 before:tw-bg-gradient-to-l before:tw-from-black/[0.07] before:tw-to-transparent before:tw-opacity-0 before:tw-transition-opacity [div[data-has-overflow=true]_&]:before:tw-opacity-100":
            isSticky,
          "tw-pl-10": checkable
        },
        className
      )}
      {...props}
    >
      {checkable && (
        <div
          className="tw-absolute tw-left-0.5 tw--my-1.5 tw-mr-0.5 tw-inline-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center"
          data-table-checkbox
        >
          <Checkbox
            checked={checkboxChecked}
            indeterminate={checkboxIndeterminate}
            disabled={checkboxDisabled}
            stopPropagation
            onChange={() => onCheckboxChange?.(!checkboxChecked)}
          />
        </div>
      )}
      {children}
    </div>
  );
};

interface SummaryProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  visible?: boolean;
}

const Summary: React.FC<SummaryProps> = ({ className, onClick, visible, children, ...props }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | undefined>(visible ? undefined : 0);

  // Update height when visibility changes
  useEffect(() => {
    if (!contentRef.current) return;

    if (visible) {
      const contentHeight = contentRef.current.scrollHeight;
      setHeight(contentHeight);
    } else {
      setHeight(0);
    }
  }, [visible]);

  return (
    <div
      className={classNames("tw-col-span-full", className)}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(e);
      }}
      {...props}
    >
      <div
        ref={contentRef}
        className="tw-overflow-hidden tw-border-t tw-border-neutral-200 tw-transition-all tw-duration-300"
        style={{
          height: height === undefined ? "auto" : `${height}px`,
          opacity: visible ? 1 : 0
        }}
      >
        {children}
      </div>
    </div>
  );
};

export const Table = Object.assign(Container, { Header, HeaderCell, Row, Cell, Summary });
