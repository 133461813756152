import { Observable } from "rxjs";

import { Query } from "@datorama/akita";

import {
  AccessibilityAnalyserQueueState,
  accessibilityAnalyserQueueStore,
  AnalysingItem,
  AnalysingStatus
} from "./accessibility-analyser-queue.store";

export class AccessibilityAnalyserQueueQuery extends Query<AccessibilityAnalyserQueueState> {
  get state(): AccessibilityAnalyserQueueState {
    return this.getValue();
  }

  get queue(): AnalysingItem[] {
    return this.state.queue;
  }

  get show(): boolean {
    return this.state.show;
  }

  get expanded(): boolean {
    return this.state.expanded;
  }

  get queueLength(): number {
    return this.state.queue.length;
  }

  get completedCount(): number {
    return this.state.queue.filter((item) => item.analysingStatus === AnalysingStatus.Completed).length;
  }

  get isAllCompleted(): boolean {
    const totalItems = this.state.queue.length;
    const completedItems = this.completedCount;
    return totalItems > 0 && completedItems === totalItems;
  }

  selectQueue(): Observable<AnalysingItem[]> {
    return this.select((state) => state.queue);
  }

  selectShow(): Observable<boolean> {
    return this.select((state) => state.show);
  }

  selectExpanded(): Observable<boolean> {
    return this.select((state) => state.expanded);
  }

  selectQueueLength(): Observable<number> {
    return this.select((state) => state.queue.length);
  }

  selectIsAllCompleted(): Observable<boolean> {
    return this.select((state) => {
      const totalItems = state.queue.length;
      const finishedItems = state.queue.filter(
        (item) => item.analysingStatus === AnalysingStatus.Completed || item.analysingStatus === AnalysingStatus.Error
      ).length;
      return totalItems > 0 && finishedItems === totalItems;
    });
  }

  selectHasErrors(): Observable<boolean> {
    return this.select((state) => state.queue.some((item) => item.analysingStatus === AnalysingStatus.Error));
  }

  selectErrorCount(): Observable<number> {
    return this.select((state) => state.queue.filter((item) => item.analysingStatus === AnalysingStatus.Error).length);
  }

  selectCompletedCount(): Observable<number> {
    return this.select(
      (state) => state.queue.filter((item) => item.analysingStatus === AnalysingStatus.Completed).length
    );
  }
  getItemById(mediaId: string): AnalysingItem | undefined {
    return this.getValue().queue.find((item) => item.mediaId === mediaId);
  }
}

export const accessibilityAnalyserQueueQuery = new AccessibilityAnalyserQueueQuery(accessibilityAnalyserQueueStore);
