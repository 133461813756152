import React from "react";

export enum StickyPosition {
  RIGHT = "right"
}

export type TableColumn = {
  label: string;
  id: string | number;
  width: string | number;
  sticky?: StickyPosition;
  sortable?: boolean;
  invertSort?: boolean;
  sortColumn?: string;
  className?: string;
  tooltip?: string;
};

export type TableItem = any;

export interface TableContext {
  columns: TableColumn[];
  items: TableItem;
}
export const TableContext = React.createContext<TableContext | null>(null);
interface TableProviderProps {
  children: React.ReactNode;
  columns: TableColumn[];
  items: TableItem;
}
export const TableProvider: React.FC<TableProviderProps> = ({ columns, items, children }) => {
  return (
    <TableContext.Provider
      value={{
        columns,
        items
      }}
    >
      {children}
    </TableContext.Provider>
  );
};
export const useTableContext = () => {
  const context = React.useContext(TableContext);

  if (context === null) {
    throw new Error("useTableContext must be used within a TableProvider");
  }

  return context;
};
