import React from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet-async";

import { updateAccountColours } from "@frontend/api/account.service";
import { getTextContrastWcagLevel } from "@frontend/utils/accessibility.utils";
import { getWcagContrastRatio } from "@frontend/utils/color.utils";

import { ALL_FONTS } from "@shared/assets/fonts";
import { ColorContrastChecker } from "@shared/components/color-contrast-checker";
import { TextAlignmentControl, TextEmphasisControl } from "@shared/components/controls";
import { TextEffectControl } from "@shared/components/controls/text-effect-control";
import { TextPositionControl } from "@shared/components/controls/text-position-control";
import { useAssets } from "@shared/hooks/use-assets";
import { useModal } from "@shared/hooks/use-modal";
import { ModalType } from "@shared/interfaces/modal-type";
import { Button } from "@shared/primitives/button";
import { NewModal } from "@shared/primitives/new-modal";
import { ToolTip } from "@shared/primitives/tooltip";
import { useAccountSettingsOutlineColorsState, useAccountSettingsPrimaryColorsState } from "@shared/state/account";

import { assColorToHex, BackgroundStyle, MediaConfig, WCAGLevel } from "@getsubly/common";
import { FontDropdown } from "@media-editor/components/dropdowns/font-dropdown";
import { FontSizeDropdown } from "@media-editor/components/dropdowns/font-size-dropdown";
import { RiArrowRightLine, RiCheckboxCircleFill, RiErrorWarningFill } from "@remixicon/react";

import { Preview } from "./ui/Preview";
import { SectionContent } from "./ui/section-content";
import { SectionIcon } from "./ui/section-icon";

type DefaultStyleProps = {
  className?: string;
  defaultMediaConfig: MediaConfig;
  onSave: (defaultMediaConfig: MediaConfig) => Promise<void>;
};
export const DefaultStyle: React.FC<DefaultStyleProps> = (props) => {
  const [showModal, hideModal] = useModal(ModalType.DefaultSubtitleStylesModal);

  const handleSave = async (updatedDefaultMediaConfig: MediaConfig) => {
    await props.onSave(updatedDefaultMediaConfig);
    hideModal();
  };

  const openModal = () => {
    showModal(
      <DefaultSubtitleStylesModal closeModal={hideModal} assStyle={props.defaultMediaConfig} onSave={handleSave} />
    );
  };

  const ratio = getWcagContrastRatio(
    assColorToHex(props.defaultMediaConfig.colorPrimary)?.slice(0, 7),
    assColorToHex(props.defaultMediaConfig.colorBack)?.slice(0, 7) || "#000"
  );

  const complianceTooltipMessage = React.useMemo(() => {
    if (!ratio) return "";
    const complianceLevel = getTextContrastWcagLevel(ratio);
    if (complianceLevel === WCAGLevel.FAIL) return "Non compliant";
    return complianceLevel;
  }, [ratio]);

  return (
    <Button
      variant="ghost"
      className={classNames("tw-flex !tw-h-min tw-items-center !tw-gap-0 !tw-p-2", props.className)}
      onClick={openModal}
    >
      <div className="tw-mr-3 tw-flex tw-items-center tw-justify-center">
        <ToolTip text={complianceTooltipMessage}>
          <SectionIcon className="!tw-m-0">
            {ratio !== null ? (
              <>
                {getTextContrastWcagLevel(ratio) === WCAGLevel.FAIL ? (
                  <RiErrorWarningFill className="tw-h-5 tw-w-5 tw-text-[#991B1B]" />
                ) : (
                  <RiCheckboxCircleFill className="tw-h-5 tw-w-5 tw-text-success-600" />
                )}
              </>
            ) : (
              "-"
            )}
          </SectionIcon>
        </ToolTip>
      </div>
      <SectionContent className="tw-text-left">
        <h3 className="tw-font-medium tw-text-neutral-900">Subtitle style</h3>
        <p className="tw-text-sm tw-text-neutral-500">
          {props.defaultMediaConfig.font}/{props.defaultMediaConfig.fontSize}px
        </p>
      </SectionContent>
      <div className="tw-pointer-events-none tw-inline-flex tw-p-2">
        <RiArrowRightLine className="tw-h-4 tw-w-4" />
      </div>
    </Button>
  );
};

interface DefaultSubtitleStylesModalProps {
  closeModal: () => void;
  onSave: (assStyle: MediaConfig) => Promise<void>;
  assStyle: MediaConfig;
}

export const DefaultSubtitleStylesModal: React.FC<DefaultSubtitleStylesModalProps> = (props) => {
  const [submittingOrder, setSubmittingOrder] = React.useState<boolean>(false);
  const [defaultMediaConfig, setDefaultMediaConfig] = React.useState(props.assStyle);
  const { fontAssets } = useAssets();
  const primaryColors = useAccountSettingsPrimaryColorsState();
  const outlineColors = useAccountSettingsOutlineColorsState();

  const handleAddCustomColor = (type: "primaryColors" | "outlineColors", color: string) => {
    if (type === "primaryColors") {
      const updatedPrimaryColors = [...new Set([...primaryColors, color])];
      updateAccountColours({ primaryColors: updatedPrimaryColors });
    } else if (type === "outlineColors") {
      const updatedOutlineColors = [...new Set([...outlineColors, color])];
      updateAccountColours({ outlineColors: updatedOutlineColors });
    }
  };

  const handleRemoveCustomColor = (type: "primaryColors" | "outlineColors", color: string) => {
    if (type === "primaryColors") {
      const updatedPrimaryColors = primaryColors.filter((_color) => _color !== color);
      updateAccountColours({ primaryColors: updatedPrimaryColors });
    } else if (type === "outlineColors") {
      const updatedOutlineColors = outlineColors.filter((_color) => _color !== color);
      updateAccountColours({ outlineColors: updatedOutlineColors });
    }
  };

  const handleSubmit = async () => {
    setSubmittingOrder(true);
    await props.onSave(defaultMediaConfig);
    setSubmittingOrder(false);
  };

  const fontFaces = React.useMemo(() => {
    const customFonts = fontAssets.map((a) => ({
      name: a.name,
      path: a.publicUrl ?? ""
    }));
    return [...customFonts, ...ALL_FONTS]
      .map(
        (f) => `
      @font-face {
        font-family: ${f.name};
        src: url(${f.path}) format("truetype");
        font-weight: normal;
      }
    `
      )
      .join("");
  }, [fontAssets.length]);

  return (
    <>
      <Helmet>
        <style type="text/css">{fontFaces}</style>
      </Helmet>
      <NewModal
        title="Default Subtitles style"
        description="Adjust how subtitles will look on the video"
        showCloseButton
        tertiaryAction="Cancel"
        onClickTertiary={props.closeModal}
        primaryAction="Save"
        onClickPrimary={handleSubmit}
        primaryActionLoading={submittingOrder}
        onDismiss={props.closeModal}
        size="930"
        className="tw-max-w-[568px] min-[978px]:tw-max-w-[930px]"
      >
        <div className="tw-mb-6 tw-flex tw-gap-4 tw-overflow-auto">
          <div className="tw-flex-1 tw-overflow-auto">
            <p className="tw-mb-2 tw-font-semibold tw-text-neutral-900">Effect</p>
            <TextEffectControl
              value={defaultMediaConfig}
              onUpdateStyleValue={(values) => setDefaultMediaConfig({ ...defaultMediaConfig, ...values })}
              primaryColors={primaryColors}
              outlineColors={outlineColors}
              addColor={handleAddCustomColor}
              removeColor={handleRemoveCustomColor}
              inputType="dropdown"
              className="tw-mb-6"
              showInPortal
            />
            {defaultMediaConfig && defaultMediaConfig.backgroundStyle !== BackgroundStyle.None && (
              <div className="tw-mb-6">
                <p className="tw-mb-2 tw-font-semibold tw-text-neutral-900">WCAG Rating</p>
                <ColorContrastChecker
                  textColor={assColorToHex(defaultMediaConfig.colorPrimary)?.slice(0, 7)}
                  backgroundColor={assColorToHex(defaultMediaConfig.colorBack)?.slice(0, 7) || "#000"}
                />
              </div>
            )}
            {defaultMediaConfig && (
              <div className="tw-flex tw-flex-col">
                <div className="tw-mb-4 tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-2">
                  <div className="tw-flex tw-flex-grow tw-flex-col">
                    {<p className="tw-mb-1 tw-text-sm tw-font-medium tw-text-neutral-900">Font</p>}
                    <FontDropdown
                      value={defaultMediaConfig.font}
                      onChange={(values: Partial<MediaConfig>) =>
                        setDefaultMediaConfig({ ...defaultMediaConfig, ...values })
                      }
                      canUpload
                    />
                  </div>
                  <div className="tw-flex tw-flex-col">
                    {<p className="tw-mb-1 tw-text-sm tw-font-medium tw-text-neutral-900">Size</p>}
                    <FontSizeDropdown
                      value={defaultMediaConfig.fontSize}
                      onChange={(fontSize: number) => setDefaultMediaConfig({ ...defaultMediaConfig, fontSize })}
                    />
                  </div>
                </div>
                <div className="tw-flex tw-flex-row tw-justify-between">
                  <TextEmphasisControl
                    value={defaultMediaConfig}
                    onUpdate={(values: Partial<MediaConfig>) =>
                      setDefaultMediaConfig({ ...defaultMediaConfig, ...values })
                    }
                  />
                  <TextAlignmentControl
                    value={defaultMediaConfig}
                    onUpdate={(values: Partial<MediaConfig>) =>
                      setDefaultMediaConfig({ ...defaultMediaConfig, ...values })
                    }
                  />
                  <div className="tw-flex tw-flex-col tw-gap-1">
                    <p className="tw-text-sm tw-font-medium tw-text-neutral-900">Subtitle Position</p>
                    <TextPositionControl
                      value={defaultMediaConfig}
                      onUpdate={(values) => setDefaultMediaConfig({ ...defaultMediaConfig, ...values })}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="tw-hidden tw-h-fit tw-w-[343px] min-[978px]:tw-flex">
            <Preview mediaConfig={defaultMediaConfig} showSubtitles />
          </div>
        </div>
      </NewModal>
    </>
  );
};
