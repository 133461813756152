import React from "react";
import classNames from "classnames";

import { Table } from "@shared/primitives/table";

interface IssueColumn<T> {
  id: string;
  label: string;
  width: string;
  render?: (item: T) => React.ReactNode;
}

interface IssueTableProps<T> {
  className?: string;
  columns: IssueColumn<T>[];
  items: T[];
}

export const IssuesTable = <T extends Record<string, any>>(props: IssueTableProps<T>) => {
  return (
    <Table columns={props.columns} items={[]} className="tw-border-none">
      <Table.Header className="tw--mb-px !tw-h-9 !tw-min-h-9 tw-overflow-hidden tw-whitespace-nowrap tw-rounded-t-7 tw-bg-neutral-50">
        {(cols) =>
          cols.map((col) => (
            <Table.HeaderCell
              key={col.id}
              columnId={col.id}
              className="!tw-h-9 !tw-min-h-9 !tw-text-[10px] !tw-text-neutral-600"
            >
              {col.label}
            </Table.HeaderCell>
          ))
        }
      </Table.Header>
      {props.items.map((item, i) => (
        <Table.Row
          className={classNames(
            "!tw-h-auto !tw-min-h-12 tw-border-b tw-border-neutral-100 last:tw-border-none",
            props.className
          )}
          key={i}
        >
          {props.columns.map((column) => (
            <Table.Cell
              key={column.id}
              columnId={column.id}
              className="!tw-border-none !tw-text-xs !tw-text-neutral-600"
            >
              {column.render ? column.render(item) : null}
            </Table.Cell>
          ))}
        </Table.Row>
      ))}
    </Table>
  );
};
