import React from "react";

import { EnrichedMediaListItem } from "@shared/interfaces/media";
import { Loader } from "@shared/primitives/loader";
import { NewModal } from "@shared/primitives/new-modal";

interface AccessibilityReportLoadingViewModalProps {
  onDismiss: () => void;
  media: EnrichedMediaListItem;
}

export const AccessibilityReportLoadingViewModal: React.FC<AccessibilityReportLoadingViewModalProps> = (props) => {
  return (
    <NewModal
      title={"Analyse Accessibility for " + props.media.name}
      description="This check will analyse your video against WCAG accessibility standards"
      showCloseButton
      tertiaryAction="Close"
      onClickTertiary={props.onDismiss}
      primaryAction="Analyse accessibility"
      primaryActionDisabled
      onDismiss={props.onDismiss}
      size="930"
      className="tw-max-w-[568px] min-[978px]:tw-max-w-[930px]"
    >
      <div className="tw-my-4 tw-min-h-0 tw-flex-1 tw-overflow-y-auto">
        <div className="tw-flex tw-h-[400px] tw-flex-col tw-items-center tw-justify-center tw-gap-12">
          <Loader className="tw-h-16 tw-w-16 tw-border-2 tw-text-neutral-600" />
        </div>
      </div>
    </NewModal>
  );
};
