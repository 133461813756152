import React from "react";
import classNames from "classnames";
import { format } from "date-fns";

import { EnrichedMediaListItem } from "@shared/interfaces/media";
import { Button } from "@shared/primitives/button";
import { Collapsible } from "@shared/primitives/collapsible";
import { NewModal } from "@shared/primitives/new-modal";
import { WcagStatus } from "@shared/primitives/wcag-status/wcag-status";
import { format as formatTimestamp } from "@shared/utils/time";

import {
  ACCESSIBILITY_CRITERIA_INFO,
  AccessibilityCriteria,
  CaptionContrastIssue,
  CaptionReport,
  CaptionsType,
  CombinedReport,
  CPLIssue,
  CPSIssue,
  languageLookup,
  transcriptionLanguages,
  translationLanguages,
  WCAGLevel
} from "@getsubly/common";

import { FlagAndLanguage } from "../components/flag-and-language";
import { Timecode } from "../components/timecode";

import { IssuesTable } from "./detailed-view/issues-table";
import { SectionTable, SectionTableRow } from "./detailed-view/section-table";

interface AccessibilityReportDetailedViewModalProps {
  media: EnrichedMediaListItem;
  report: CombinedReport;
  analysedAt: string;
  disableReAnalyse?: boolean;
  onDismiss: () => void;
  onClickAnalyse: () => void;
  onClickSummaryView: () => void;
}

interface SectionColumn {
  id: string;
  label: string;
  width: string;
}

interface SummaryColumn<T> extends SectionColumn {
  render?: (item: T) => React.ReactNode;
}

interface SectionTableCellData {
  id: string;
  value: string | React.ReactNode;
}

interface SectionRowData<T = any> {
  cells: SectionTableCellData[];
  summaryColumns?: SummaryColumn<T>[];
  summaryItems?: T[];
  withSummary?: boolean;
}

interface SectionData {
  title: string;
  columns: SectionColumn[];
  rows: SectionRowData[];
  defaultOpen?: boolean;
}

interface ReportCategory {
  title?: string;
  sections: SectionData[];
}

enum CriteriaColumnId {
  FEATURE = "feature",
  WCAG2_2 = "WCAG 2.2",
  TIMECODE = "Timecode",
  REMEDIATION = "Remediation"
}

export const AccessibilityReportDetailedViewModal: React.FC<AccessibilityReportDetailedViewModalProps> = (props) => {
  const language = React.useMemo(
    () => languageLookup(transcriptionLanguages, props.media.languageCode),
    [props.media.languageCode]
  );

  const openCaptionReport = React.useMemo(() => {
    return props.report.captionReports.find(
      (report) => report.languageCode === props.media.languageCode && report.captionsType === CaptionsType.OPEN
    );
  }, [props.report.captionReports, props.media.languageCode]);

  const mainCaptionReport = React.useMemo(() => {
    return props.report.captionReports.find(
      (report) => report.languageCode === props.media.languageCode && report.captionsType === CaptionsType.CLOSED
    );
  }, [props.report.captionReports, props.media.languageCode]);

  const otherCaptionReports = React.useMemo(() => {
    return props.report.captionReports.filter((report) => report.languageCode !== props.media.languageCode);
  }, [props.report.captionReports, props.media.languageCode]);

  const reportStructure = React.useMemo(() => {
    const reportCategory: ReportCategory[] = [];

    // Primary Video Accessibility Issues (First Section with heading)
    const primarySections: SectionData[] = [];

    // 1. Flashing Content Section
    const flashingContentCriteria = props.report[AccessibilityCriteria.FLASHING_CONTENT];
    const flashingContentInfo = ACCESSIBILITY_CRITERIA_INFO[AccessibilityCriteria.FLASHING_CONTENT];
    if (flashingContentCriteria?.WCAGLevel) {
      primarySections.push({
        title: "Flashing Content",
        defaultOpen: true,
        columns: [
          { id: CriteriaColumnId.FEATURE, label: "Feature", width: "auto" },
          { id: CriteriaColumnId.WCAG2_2, label: "WCAG 2.2", width: "auto" },
          { id: CriteriaColumnId.TIMECODE, label: "Timecode", width: "auto" },
          { id: CriteriaColumnId.REMEDIATION, label: "Remediation", width: "auto" }
        ],
        rows: [
          {
            cells: [
              { id: CriteriaColumnId.FEATURE, value: flashingContentInfo.name },
              { id: CriteriaColumnId.WCAG2_2, value: <WcagStatus wcagLevel={flashingContentCriteria.WCAGLevel} /> },
            { id: CriteriaColumnId.TIMECODE, value: <Timecode timestamps={flashingContentCriteria.WCAGIssues.map((issue) => issue.start)}/> }, // eslint-disable-line
            { id: CriteriaColumnId.REMEDIATION, value: flashingContentCriteria.WCAGLevel === WCAGLevel.PASS_AAA ? "-" : flashingContentInfo.actionRequired } // eslint-disable-line
            ],
            withSummary: true,
            summaryColumns: [
            { id: "timecode", label: "Timecode", width: "auto", render: (item) => formatTimestamp(item.start, item.start >= 3600 * 1000) }, // eslint-disable-line
            { id: "flashes-per-second", label: "Flashes per Second", width: "auto", render: (item) => item.flashes + " flashes/sec" }, // eslint-disable-line
              { id: "action-required", label: "Action Required", width: "auto", render: (item) => item.remediation }
            ],
            summaryItems: flashingContentCriteria.WCAGIssues
          }
        ]
      });
    }

    // 2. Open Captions Section
    if (openCaptionReport) {
      const captionContrastCriteria = openCaptionReport[AccessibilityCriteria.CAPTION_CONTRAST];
      const captionContrastInfo = ACCESSIBILITY_CRITERIA_INFO[AccessibilityCriteria.CAPTION_CONTRAST];
      const captionContrastRemediation = captionContrastCriteria?.remediation;

      const cpsCriteria = openCaptionReport[AccessibilityCriteria.CPS];
      const cpsInfo = ACCESSIBILITY_CRITERIA_INFO[AccessibilityCriteria.CPS];
      const cpsRemediation = cpsCriteria?.remediation;

      const cplCriteria = openCaptionReport[AccessibilityCriteria.CPL];
      const cplInfo = ACCESSIBILITY_CRITERIA_INFO[AccessibilityCriteria.CPL];
      const cplRemediation = cplCriteria?.remediation;

      primarySections.push({
        title: "Open Captions (Burned-in Captions)",
        defaultOpen: true,
        columns: [
          { id: CriteriaColumnId.FEATURE, label: "Feature", width: "auto" },
          { id: CriteriaColumnId.WCAG2_2, label: "WCAG 2.2", width: "auto" },
          { id: CriteriaColumnId.TIMECODE, label: "Timecode", width: "auto" },
          { id: CriteriaColumnId.REMEDIATION, label: "Remediation", width: "auto" }
        ],
        rows: [
          // Caption Contrast
          captionContrastCriteria &&
            ({
              cells: [
                { id: CriteriaColumnId.FEATURE, value: captionContrastInfo.name },
                { id: CriteriaColumnId.WCAG2_2, value: <WcagStatus wcagLevel={captionContrastCriteria.WCAGLevel} /> },
                { id: CriteriaColumnId.TIMECODE, value: <Timecode timestamps={captionContrastCriteria.WCAGIssues.map((issue) => issue.start)}/> }, // eslint-disable-line
                { id: CriteriaColumnId.REMEDIATION, value: captionContrastRemediation } // eslint-disable-line
              ],
              withSummary: true,
              summaryColumns: [
                { id: "timecode", label: "Timecode", width: "auto", render: (item: CaptionContrastIssue) => formatTimestamp(item.start, item.start >= 3600 * 1000) }, // eslint-disable-line
                { id: "contrast", label: "Contrast Ratio", width: "auto", render: (item: CaptionContrastIssue) => item.contrastRatio + ':1' }, // eslint-disable-line
                { id: "action-required", label: "Action Required", width: "auto", render: (item: CaptionContrastIssue) => item.remediation } // eslint-disable-line
              ],
              summaryItems: captionContrastCriteria.WCAGIssues
            } as SectionRowData<CaptionContrastIssue>),
          // CPS Compliance
          cpsCriteria &&
            ({
              cells: [
                { id: CriteriaColumnId.FEATURE, value: cpsInfo.name },
                { id: CriteriaColumnId.WCAG2_2, value: <WcagStatus wcagLevel={cpsCriteria.WCAGLevel} /> },
                { id: CriteriaColumnId.TIMECODE, value: <Timecode timestamps={cpsCriteria.WCAGIssues.map((issue) => issue.start)}/> }, // eslint-disable-line
                { id: CriteriaColumnId.REMEDIATION, value: cpsRemediation } // eslint-disable-line
              ],
              withSummary: true,
              summaryColumns: [
                { id: "timecode", label: "Timecode", width: "auto", render: (item) => formatTimestamp(item.start, item.start >= 3600 * 1000) }, // eslint-disable-line
                { id: "characters-per-second", label: "Characters Per Second", width: "auto", render: (item) => item.cps }, // eslint-disable-line
                { id: "action-required", label: "Action Required", width: "auto", render: (item) => item.remediation } // eslint-disable-line
              ],
              summaryItems: cpsCriteria.WCAGIssues
            } as SectionRowData<CPSIssue>),
          // CPL Compliance
          cplCriteria &&
            ({
              cells: [
                { id: CriteriaColumnId.FEATURE, value: cplInfo.name },
                { id: CriteriaColumnId.WCAG2_2, value: <WcagStatus wcagLevel={cplCriteria.WCAGLevel} /> },
                { id: CriteriaColumnId.TIMECODE, value: <Timecode timestamps={cplCriteria.WCAGIssues.map((issue) => issue.start)}/> }, // eslint-disable-line
                { id: CriteriaColumnId.REMEDIATION, value: cplRemediation } // eslint-disable-line
              ],
              withSummary: true,
              summaryColumns: [
                { id: "timecode", label: "Timecode", width: "auto", render: (item) => formatTimestamp(item.start, item.start >= 3600 * 1000) }, // eslint-disable-line
                { id: "characters-per-line", label: "Characters Per Line", width: "auto", render: (item) => item.cpl }, // eslint-disable-line
                { id: "action-required", label: "Action Required", width: "auto", render: (item) => item.remediation } // eslint-disable-line
              ],
              summaryItems: cplCriteria.WCAGIssues
            } as SectionRowData<CPLIssue>)
        ].filter(Boolean) as NonNullable<SectionRowData<CaptionContrastIssue | CPSIssue | CPLIssue>>[]
      });
    }

    // 3. Caption File Availability Section
    primarySections.push({
      title: "Caption File Availability",
      defaultOpen: true,
      columns: [
        { id: "language", label: "Language", width: "auto" },
        { id: "wcag2.2", label: `WCAG 2.2 (${mainCaptionReport ? "Required" : "Optional"})`, width: "auto" }
      ],
      rows: mainCaptionReport
        ? [
            {
              cells: [
                { id: "language", value: <FlagAndLanguage languageCode={mainCaptionReport.languageCode} /> },
                { id: "wcag2.2", value: <WcagStatus wcagLevel={mainCaptionReport.WCAGCaptionCompliance} /> }
              ]
            }
          ]
        : []
    });

    // 4. Main Language Caption File Section
    if (mainCaptionReport) {
      primarySections.push(createTranslationFileSection("Caption File", mainCaptionReport, { defaultOpen: true }));
    }

    // Add the primary section group
    reportCategory.push({
      title: `1. Primary Video Accessibility Issues ${language ? `(Main Language - ${language.language})` : ""}`,
      sections: primarySections
    });

    // Optional Languages Section (Second major section with heading)
    if (otherCaptionReports.length > 0) {
      const optionalSections: SectionData[] = [];

      // Caption file availability for other languages
      optionalSections.push({
        title: "Optional Language Caption File Availability",
        columns: [
          { id: "language", label: "Language", width: "auto" },
          { id: "wcag2.2", label: "WCAG 2.2 (Optional)", width: "auto" }
        ],
        rows: otherCaptionReports.map((report) => ({
          cells: [
            { id: "language", value: <FlagAndLanguage languageCode={report.languageCode} isTranslation /> },
            { id: "wcag2.2", value: <WcagStatus wcagLevel={report.WCAGCaptionCompliance} /> }
          ]
        }))
      });

      // Translation file sections for each language
      otherCaptionReports.forEach((report) => {
        const language = languageLookup(translationLanguages, report.languageCode);
        optionalSections.push(
          createTranslationFileSection(`${language?.language ?? report.languageCode} Captions`, report)
        );
      });

      // Add the optional languages section group
      reportCategory.push({
        title: "2. Optional Languages & Additional checks",
        sections: optionalSections
      });
    }

    return reportCategory;

    // Helper function to create a translation file section
    function createTranslationFileSection(
      label: string,
      report: CaptionReport,
      options?: { defaultOpen: boolean }
    ): SectionData {
      const captionContrastCriteria = report[AccessibilityCriteria.CAPTION_CONTRAST];
      const captionContrastInfo = ACCESSIBILITY_CRITERIA_INFO[AccessibilityCriteria.CAPTION_CONTRAST];
      const captionContrastRemediation = captionContrastCriteria?.remediation;

      const cpsCriteria = report[AccessibilityCriteria.CPS];
      const cpsInfo = ACCESSIBILITY_CRITERIA_INFO[AccessibilityCriteria.CPS];
      const cpsRemediation = cpsCriteria?.remediation;

      const cplCriteria = report[AccessibilityCriteria.CPL];
      const cplInfo = ACCESSIBILITY_CRITERIA_INFO[AccessibilityCriteria.CPL];
      const cplRemediation = cplCriteria?.remediation;

      return {
        title: label,
        defaultOpen: options?.defaultOpen,
        columns: [
          { id: CriteriaColumnId.FEATURE, label: "Feature", width: "auto" },
          { id: CriteriaColumnId.WCAG2_2, label: "WCAG 2.2", width: "auto" },
          { id: CriteriaColumnId.TIMECODE, label: "Timecode", width: "auto" },
          { id: CriteriaColumnId.REMEDIATION, label: "Remediation", width: "auto" }
        ],
        rows: [
          // Caption Contrast
          captionContrastCriteria &&
            ({
              cells: [
                { id: CriteriaColumnId.FEATURE, value: captionContrastInfo.name },
                { id: CriteriaColumnId.WCAG2_2, value: <WcagStatus wcagLevel={captionContrastCriteria.WCAGLevel} /> },
                { id: CriteriaColumnId.TIMECODE, value: <Timecode timestamps={captionContrastCriteria.WCAGIssues.map((issue) => issue.start)}/> }, // eslint-disable-line
                { id: CriteriaColumnId.REMEDIATION, value: captionContrastRemediation } // eslint-disable-line
              ],
              withSummary: true,
              summaryColumns: [
                { id: "timecode", label: "Timecode", width: "auto", render: (item) => formatTimestamp(item.start, item.start >= 3600 * 1000) }, // eslint-disable-line
                { id: "contrast", label: "Contrast Ratio", width: "auto", render: (item) => item.contrastRatio + ':1' }, // eslint-disable-line
                { id: "action-required", label: "Action Required", width: "auto", render: (item) => item.remediation } // eslint-disable-line
              ],
              summaryItems: captionContrastCriteria.WCAGIssues
            } as SectionRowData<CaptionContrastIssue>),
          // CPS Compliance
          cpsCriteria &&
            ({
              cells: [
                { id: CriteriaColumnId.FEATURE, value: cpsInfo.name },
                { id: CriteriaColumnId.WCAG2_2, value: <WcagStatus wcagLevel={cpsCriteria.WCAGLevel} /> },
                { id: CriteriaColumnId.TIMECODE, value: <Timecode timestamps={cpsCriteria.WCAGIssues.map((issue) => issue.start)}/> }, // eslint-disable-line
                { id: CriteriaColumnId.REMEDIATION, value: cpsRemediation } // eslint-disable-line
              ],
              withSummary: true,
              summaryColumns: [
                { id: "timecode", label: "Timecode", width: "auto", render: (item: CPSIssue) => formatTimestamp(item.start, item.start >= 3600 * 1000) }, // eslint-disable-line
                { id: "characters-per-second", label: "Characters Per Second", width: "auto", render: (item: CPSIssue) => item.cps }, // eslint-disable-line
                { id: "action-required", label: "Action Required", width: "auto", render: (item: CPSIssue) => item.remediation } // eslint-disable-line
              ],
              summaryItems: cpsCriteria.WCAGIssues
            } as SectionRowData<CPSIssue>),
          // CPL Compliance
          cplCriteria &&
            ({
              cells: [
                { id: CriteriaColumnId.FEATURE, value: cplInfo.name },
                { id: CriteriaColumnId.WCAG2_2, value: <WcagStatus wcagLevel={cplCriteria.WCAGLevel} /> },
                { id: CriteriaColumnId.TIMECODE, value: <Timecode timestamps={cplCriteria.WCAGIssues.map((issue) => issue.start)}/> }, // eslint-disable-line
                { id: CriteriaColumnId.REMEDIATION, value: cplRemediation } // eslint-disable-line
              ],
              withSummary: true,
              summaryColumns: [
                { id: "timecode", label: "Timecode", width: "auto", render: (item: CPLIssue) => formatTimestamp(item.start, item.start >= 3600 * 1000) }, // eslint-disable-line
                { id: "characters-per-line", label: "Characters Per Line", width: "auto", render: (item: CPLIssue) => item.cpl }, // eslint-disable-line
                { id: "action-required", label: "Action Required", width: "auto", render: (item: CPLIssue) => item.remediation } // eslint-disable-line
              ],
              summaryItems: cplCriteria.WCAGIssues
            } as SectionRowData<CPLIssue>)
        ].filter(Boolean) as NonNullable<SectionRowData<CaptionContrastIssue | CPSIssue | CPLIssue>>[]
      };
    }
  }, [props.report, mainCaptionReport, otherCaptionReports, language]);

  return (
    <NewModal
      title={"Analyse Accessibility for " + props.media.name}
      description="Review the detected issues and recommended fixes to improve accessibility."
      showCloseButton
      onDismiss={props.onDismiss}
      size="930"
      className="!tw-max-h-[calc(100%_-_100px)] tw-max-w-[568px] min-[978px]:tw-max-w-[930px]"
    >
      <span className="tw--mt-1 tw-text-sm tw-text-neutral-500">
        Made on {format(new Date(props.analysedAt), "d MMMM yyyy")}
      </span>
      <div className="tw-my-4 tw-min-h-0 tw-flex-1 tw-overflow-y-auto">
        {/* Render each report category with its heading */}
        {reportStructure.map((category, groupIndex) => (
          <React.Fragment key={groupIndex}>
            {/* Section group heading (h3) */}
            <h3 className={classNames("tw-mb-6 tw-text-lg tw-font-medium", groupIndex > 0 ? "tw-mt-6" : "tw-mt-2")}>
              {category.title}
            </h3>

            {/* Render all sections in this category */}
            {category.sections.map((section, sectionIndex) => (
              <Collapsible key={sectionIndex} heading={section.title} defaultOpen={section.defaultOpen}>
                <SectionTable columns={section.columns}>
                  {section.rows.map((row, rowIndex) => (
                    <SectionTableRow
                      key={rowIndex}
                      cells={row.cells}
                      className="tw-overflow-hidden tw-rounded-b-7"
                      withSummary={row.withSummary}
                      disableSummaryButton={!row.summaryItems || row.summaryItems.length === 0}
                    >
                      {row.withSummary && row.summaryColumns && row.summaryItems && (
                        <IssuesTable columns={row.summaryColumns} items={row.summaryItems} />
                      )}
                    </SectionTableRow>
                  ))}
                </SectionTable>
              </Collapsible>
            ))}
          </React.Fragment>
        ))}
      </div>
      <div className="tw-flex tw-items-center tw-justify-end tw-gap-3">
        <Button variant="secondary" className="tw-mr-auto" size="32" onClick={props.onDismiss}>
          Close
        </Button>
        <Button variant="secondary" size="32" onClick={props.onClickAnalyse} disabled={props.disableReAnalyse}>
          Re-Analyse
        </Button>
        <Button variant="primary" size="32" onClick={props.onClickSummaryView}>
          View Summary
        </Button>
      </div>
    </NewModal>
  );
};
