import { UploadFileType, uploadQuery, UploadQueueState } from "@shared/state/upload-queue";

import { useObservable } from "@mindspace-io/react";

interface UseUpload extends UploadQueueState {
  totalCount: number;
  totalDuration: number;
  totalSize: number;
  sortedQueue: UploadFileType[];
  stagedQueue: UploadFileType[];
  uploadQueue: UploadFileType[];
  isUploading: boolean;
  uploadingMediaId?: string;
  uploadQueueLength: number;
}

export function useUpload(): UseUpload {
  const [upload] = useObservable(uploadQuery.select(), uploadQuery.getValue());
  const [totalCount] = useObservable(uploadQuery.selectTotalCount(), 0);
  const [totalDuration] = useObservable(uploadQuery.selectTotalDuration(), 0);
  const [totalSize] = useObservable(uploadQuery.selectTotalSize(), 0);
  const [sortedQueue] = useObservable(uploadQuery.selectSortedQueue(), []);
  const [stagedQueue] = useObservable(uploadQuery.selectStagedQueue(), []);
  const [uploadQueue] = useObservable(uploadQuery.selectUploadQueue(), []);
  const [uploadingMediaId] = useObservable(uploadQuery.selectUploadingMediaId());
  const [uploadQueueLength] = useObservable(uploadQuery.selectUploadQueueLength(), uploadQuery.uploadQueueLength);

  return {
    totalCount,
    totalDuration,
    totalSize,
    sortedQueue,
    stagedQueue,
    uploadQueue,
    uploadingMediaId,
    uploadQueueLength,
    ...upload
  };
}
