import React, { useRef, useState } from "react";
import { nextTick } from "process";

import { RiArrowDownSLine, RiArrowRightSLine } from "@remixicon/react";

interface CollapsibleProps {
  heading: string;
  defaultOpen?: boolean;
  children: React.ReactNode;
  className?: string;
}

export const Collapsible: React.FC<CollapsibleProps> = ({ heading, defaultOpen = false, children, className = "" }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [isToggling, setToggling] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | undefined>(defaultOpen ? undefined : 0);

  const toggleOpen = () => {
    if (!contentRef.current) return;

    contentRef.current.classList.add("tw-transition-all");

    const isOpening = !isOpen;

    if (!isToggling) {
      setHeight(contentRef.current.scrollHeight);
    }

    nextTick(() => {
      if (!contentRef.current) return;

      setHeight(isOpening ? contentRef.current.scrollHeight : 0);

      setToggling(true);

      setIsOpen((prevState) => !prevState);
    });
  };

  React.useEffect(() => {
    contentRef.current!.addEventListener("transitionend", (event) => {
      if (event.propertyName === "height") {
        setHeight(isOpen ? undefined : 0);
        setToggling(false);
        contentRef.current?.classList.remove("tw-transition-all");
      }
    });
  }, [isOpen]);

  return (
    <div className={`tw-mb-4 ${className}`}>
      <button className="tw-flex tw-w-full tw-items-center tw-gap-1" onClick={toggleOpen} aria-expanded={isOpen}>
        {isOpen ? (
          <RiArrowDownSLine className="tw-h-5 tw-w-5 tw-text-neutral-600" />
        ) : (
          <RiArrowRightSLine className="tw-h-5 tw-w-5 tw-text-neutral-600" />
        )}
        <h4 className="tw-text-sm tw-font-medium tw-text-neutral-900">{heading}</h4>
      </button>

      <div
        ref={contentRef}
        className="tw-overflow-hidden tw-duration-300"
        style={{
          height: height === undefined ? "auto" : `${height}px`,
          opacity: isOpen ? 1 : 0
        }}
      >
        <div className="tw-mt-3">{children}</div>
      </div>
    </div>
  );
};
