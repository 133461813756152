import { abortAccessibilityReport } from "@frontend/api/media.service";

import { EnrichedMediaListItem } from "@shared/interfaces/media";

import { Store, StoreConfig } from "@datorama/akita";

export enum AnalysingStatus {
  Analysing = "analysing",
  Completed = "completed",
  Aborted = "aborted",
  Error = "error"
}

export interface AnalysingItem {
  analysingStatus: AnalysingStatus;
  mediaId: string;
  media: EnrichedMediaListItem;
  error?: string;
}

export interface AccessibilityAnalyserQueueState {
  queue: AnalysingItem[];
  show: boolean;
  expanded: boolean;
}

export const createInitialState = (): AccessibilityAnalyserQueueState => ({
  queue: [],
  show: false,
  expanded: true
});

@StoreConfig({ name: "accessibilityAnalyserQueue", resettable: true })
export class AccessibilityAnalyserQueueStore extends Store<AccessibilityAnalyserQueueState> {
  constructor() {
    super(createInitialState());
  }

  addItemToQueue = (item: AnalysingItem): void => {
    this.update((state) => ({
      ...state,
      queue: [...state.queue, item],
      show: true
    }));
  };

  updateItemInQueue = (mediaId: string, updatedItem: Partial<AnalysingItem>): void => {
    this.update((state) => {
      const updatedQueue = state.queue.map((item) => (item.mediaId === mediaId ? { ...item, ...updatedItem } : item));

      return {
        ...state,
        queue: updatedQueue
      };
    });
  };

  clearQueue = (): void => {
    this.update((state) => ({
      ...state,
      queue: []
    }));
  };

  setShow = (show: boolean): void => {
    this.update((state) => ({ ...state, show }));
  };

  setExpanded = (expanded: boolean): void => {
    this.update((state) => ({ ...state, expanded }));
  };

  abortAnalysis = (mediaId: string): void => {
    this.updateItemInQueue(mediaId, {
      analysingStatus: AnalysingStatus.Aborted
    });

    abortAccessibilityReport(mediaId);
  };
}

export const accessibilityAnalyserQueueStore = new AccessibilityAnalyserQueueStore();
