import { WCAGLevel } from "@getsubly/common";

const CONTRAST_THRESHOLDS = {
  [WCAGLevel.PASS_AAA]: 7.0,
  [WCAGLevel.PASS_AA]: 4.5
} as const;

export const getTextContrastWcagLevel = (
  contrastRatio: number
): WCAGLevel.PASS_AAA | WCAGLevel.PASS_AA | WCAGLevel.FAIL => {
  if (contrastRatio >= CONTRAST_THRESHOLDS[WCAGLevel.PASS_AAA]) return WCAGLevel.PASS_AAA;
  if (contrastRatio >= CONTRAST_THRESHOLDS[WCAGLevel.PASS_AA]) return WCAGLevel.PASS_AA;
  return WCAGLevel.FAIL;
};
