import { WCAGLevel } from "@getsubly/common";

import { NewBadge } from "../new-badge";

type WcagBadgeProps = {
  wcagLevel: WCAGLevel;
};
export const WcagBadge: React.FC<WcagBadgeProps> = ({ wcagLevel }) => {
  switch (wcagLevel) {
    case WCAGLevel.PASS_AAA:
      return (
        <NewBadge corner="rounded" size="28" variant="success-soft">
          AAA
        </NewBadge>
      );
    case WCAGLevel.PASS_AA:
      return (
        <NewBadge corner="rounded" size="28" variant="primary-soft">
          AA
        </NewBadge>
      );
    case WCAGLevel.PASS_A:
      return (
        <NewBadge corner="rounded" size="28" variant="warning-soft">
          A
        </NewBadge>
      );
    case WCAGLevel.FAIL:
      return (
        <NewBadge corner="rounded" size="28" variant="destructive-soft">
          Not Compliant
        </NewBadge>
      );
    default:
      return "-";
  }
};
