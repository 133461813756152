// Compute the cost of the analysis

export const getCost = ({
  isEnterprise,
  mediaDurationSeconds
}: {
  isEnterprise: boolean;
  mediaDurationSeconds: number;
}) => {
  const BUSINESS_MINUTES_PER_MEDIA_MINUTE = 28;
  const ENTERPRISE_MINUTES_PER_MEDIA_MINUTE = 35;

  const mediaDurationMinutes = mediaDurationSeconds / 60;

  if (isEnterprise) {
    return Math.ceil(ENTERPRISE_MINUTES_PER_MEDIA_MINUTE * mediaDurationMinutes);
  }

  return Math.ceil(BUSINESS_MINUTES_PER_MEDIA_MINUTE * mediaDurationMinutes);
};
