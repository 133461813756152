import axios from "axios";

import { EN } from "@frontend/assets/i18n/en";
import config from "@frontend/config";

import { notificationError } from "@shared/primitives/notification";
import { assetsStore } from "@shared/state/assets/assets.store";
import { commentsStore } from "@shared/state/comments";
import { editorStateRepository } from "@shared/state/editor/editor.state";
import { editorUiStateRepository } from "@shared/state/editor-ui";
import { ordersQuery } from "@shared/state/orders";
import { convertMediaToEnrichedMedia } from "@shared/utils/media-functions";

import { MediaConfig } from "@getsubly/common";
import { MediaEditor, MediaSummary, TranscriptionMap } from "@media-editor/types";

import { getAccessToken } from "./auth.service";
import { handleError } from "./handle-error";
import { updateActiveMediaBurnProgressStatus } from "./media.service";

const baseURL = `${config.apiUrl}/api/v1`;

const load = async (mediaId: string): Promise<MediaEditor | undefined> => {
  try {
    editorStateRepository.updateState({ editorLoading: true });

    const { data } = await axios.get<MediaEditor>(`/media/${mediaId}/editor?useTransformer=true`, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    return data;
  } catch (e) {
    if (e.response?.status === 404) {
      notificationError(EN.media.NotFound);
    } else {
      handleError(e);
    }
  } finally {
    editorStateRepository.updateState({ editorLoading: false });
  }
};

export const loadMediaEditor = async (
  mediaId: string
): Promise<{
  accountId: string;
  assConfig: MediaConfig;
  transcriptions: TranscriptionMap;
  isTranscribing: boolean;
  summary?: MediaSummary;
}> => {
  assetsStore.update({ loading: true });
  commentsStore.update({ loading: true });
  ordersQuery.updateState({ isLoading: true });

  const data = await load(mediaId);

  if (!data) {
    throw Error("Failed to load media");
  }
  const { account, media: resMedia, assets, comments, orders } = data;

  // Media
  const enrichedMedia = convertMediaToEnrichedMedia(resMedia, {
    plan: account?.plan
  });

  editorStateRepository.updateState({ media: enrichedMedia });

  await updateActiveMediaBurnProgressStatus();

  // Assets
  assetsStore.set(assets);
  assetsStore.update({ loading: false });

  // Comments
  commentsStore.set(comments);
  commentsStore.update({ loading: false });

  // Orders
  ordersQuery.updateState({
    orders: orders,
    isLoading: false,
    hasLoaded: true
  });

  editorStateRepository.updateState({ editorLoaded: true });

  editorUiStateRepository.updateState({
    accountSettings: account.settings
  });

  const { media, transcriptions, summary } = data;

  if (!media) {
    throw Error("Failed to load media");
  }

  return {
    accountId: media.accountId,
    assConfig: media.assConfig,
    transcriptions,
    isTranscribing: enrichedMedia.isTranscribing,
    summary
  };
};
