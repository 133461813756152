import { FlagSymbol } from "@shared/components/symbols/flag-symbol";

import { languageLookup, transcriptionLanguages, translationLanguages } from "@getsubly/common";

type FlagAndLanguageProps = {
  languageCode: string;
  isTranslation?: boolean;
};

export const FlagAndLanguage: React.FC<FlagAndLanguageProps> = (props) => {
  const language = languageLookup(
    props.isTranslation ? translationLanguages : transcriptionLanguages,
    props.languageCode
  );
  if (!language) return "-";
  return (
    <div className="tw-inline-flex tw-items-center tw-gap-2 tw-whitespace-nowrap">
      <FlagSymbol size="24" flag={{ flagCode: language.flagCode, label: language.language }} /> {language.language}
    </div>
  );
};
