import React from "react";

import { Button, ButtonVariant } from "@shared/primitives/button";
import { NewModal } from "@shared/primitives/new-modal";

interface ConfirmProps {
  title?: string;
  description?: string;
  confirmButtonText?: string;
  confirmButtonVariant?: ButtonVariant;
  placeholder?: string;
  defaultValue?: string;
  isVerticallyCentred?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}
export const Confirm: React.FC<ConfirmProps> = ({
  title,
  description,
  confirmButtonText = "Confirm",
  confirmButtonVariant = "primary",
  isVerticallyCentred = false,
  onConfirm,
  onCancel
}) => {
  const [isConfirming, setConfirming] = React.useState<boolean>(false);

  const handleConfirm = async () => {
    setConfirming(true);
    await onConfirm();
    setConfirming(false);
    onCancel();
  };
  return (
    <NewModal
      onDismiss={onCancel}
      disableBackgroundDismiss
      headerClassName="tw-px-4"
      title={title}
      description={description}
      isVerticallyCentred={isVerticallyCentred}
    >
      <div className="tw-mt-4 tw-flex">
        <Button variant="secondary" onClick={onCancel} className="tw-ml-auto tw-mr-2" size="36">
          Cancel
        </Button>
        <Button
          variant={confirmButtonVariant}
          onClick={handleConfirm}
          className="tw-mx-2"
          loading={isConfirming}
          size="36"
        >
          {confirmButtonText}
        </Button>
      </div>
    </NewModal>
  );
};
