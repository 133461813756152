import { AxiosRequestConfig } from "axios";

import { getAccountId } from "@frontend/config/settings/settings.service";

import { MediaListItem } from "@shared/interfaces/media";
import { UserGroup } from "@shared/interfaces/user-group";
import { Folder, VirtualFolderId } from "@shared/state/dashboard/dashboard.store";

import { FileType, Order, OrderService } from "@getsubly/common";

import { sendGetRequest, sendPostRequest, sendPutRequest } from "./rest-api.utils";

/**
 * GET /api/v2/:account_id/dashboard/user-groups - Get user groups
 */

export type getDashboardGroupsV2Response = {
  userGroups: UserGroup[];
};

export const GET_v2_dashboard_user_groups = () => sendGetRequest<getDashboardGroupsV2Response>(`/api/v2/${getAccountId()}/dashboard/user-groups`); // prettier-ignore

/**
 * GET /api/v2/:account_id/dashboard/folders - Get dashboard folders
 */

export type getDashboardFoldersV2Response = {
  folders: Folder[];
};

export const GET_v2_dashboard_folders = () => sendGetRequest<getDashboardFoldersV2Response>(`/api/v2/${getAccountId()}/dashboard/folders`); // prettier-ignore

/**
 * POST /api/v2/:account_id/dashboard/favourite-folder - Toggle favourite folder
 */

export const POST_v2_dashboard_favourite_toggle = (folderId: string) => sendPostRequest(`/api/v2/${getAccountId()}/dashboard/favourite-folder`, { folderId }) // prettier-ignore

/**
 * GET /api/v2/:account_id/media - Get media list
 */

export enum MediaListSortColumn {
  NAME = "name",
  LANGUAGE = "language",
  TEXT_COLOR_CONTRAST_RATIO = "text_color_contrast_ratio",
  WCAG_OVERALL_COMPLIANCE = "wcag_overall_compliance",
  LENGTH = "length",
  SIZE = "size",
  CREATED = "created_at",
  UPDATED = "updated_at"
}

export type getMediaListV2Params = {
  skip?: number | null;
  limit?: number | null;
  sort?: MediaListSortColumn;
  order?: "asc" | "desc" | null;
  folderId?: string | VirtualFolderId | null;
  type?: FileType | null;
};

export type getMediaListV2Response = {
  items: MediaListItem[];
  count: number;
  total: number;
};

export const GET_v2_media_list = (config: AxiosRequestConfig & { params:  getMediaListV2Params } ) => sendGetRequest<getMediaListV2Response>(`/api/v2/${getAccountId()}/media`, config) // prettier-ignore

/**
 * GET /api/v2/:account_id/media/:media_id - Get media list item
 */

export type mediaListItemResponse = {
  media: MediaListItem;
};

export const GET_v2_media_list_item = (mediaId: string, config?: AxiosRequestConfig ) => sendGetRequest<mediaListItemResponse>(`/api/v2/${getAccountId()}/media/${mediaId}`, config) // prettier-ignore

/**
 * GET /api/v1/:account_id/orders - Get order list
 */

export type getOrderListResponse = {
  orders: Order[];
  count: number;
};

const GET_v1_order_list = (config?: AxiosRequestConfig) => sendGetRequest<getOrderListResponse>(`/api/v1/${getAccountId()}/orders`, config) // prettier-ignore

/**
 * GET /api/v1/:account_id/orders/:order_id - Get order
 */

export type getOrderResponse = {
  order: Order;
};

const GET_v1_order = (orderId: string, config?: AxiosRequestConfig) => sendGetRequest<getOrderResponse>(`/api/v1/${getAccountId()}/orders/${orderId}`, config) // prettier-ignore

/**
 * POST /api/v1/:account_id/orders - Create order
 */

export type createOrderResponse = {
  order: Order;
};

const POST_v1_create_order = (mediaId: string, services: OrderService[], config?: AxiosRequestConfig) => sendPostRequest<createOrderResponse>(`/api/v1/${getAccountId()}/orders`, { mediaId, services }, config) // prettier-ignore

/**
 * PUT /api/v1/:account_id/orders/:order_id/cancel
 */

export type cancelOrderResponse = {
  success: boolean;
};

const PUT_v1_cancel_order = (orderId: string, config?: AxiosRequestConfig) => sendPutRequest<cancelOrderResponse>(`/api/v1/${getAccountId()}/orders/${orderId}/cancel`, config) // prettier-ignore

export default {
  // Dashboard
  GET_v2_dashboard_user_groups,
  GET_v2_dashboard_folders,
  POST_v2_dashboard_favourite_toggle,
  // Media
  GET_v2_media_list,
  GET_v2_media_list_item,
  // Order
  GET_v1_order_list,
  GET_v1_order,
  POST_v1_create_order,
  PUT_v1_cancel_order
};
