import React from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { getUsageOverview } from "@frontend/api/usage.service";
import { StorageCard } from "@frontend/components/settings/usage/storage-card";
import config from "@frontend/config";
import { getAccountId } from "@frontend/config/settings/settings.service";
import { SettingsPageHeader } from "@frontend/layouts/settings.layout";
import { SETTINGS_USAGE_TRANSACTIONS_PATH } from "@frontend/routes";

import { Loader } from "@shared/primitives/loader";
import { Table } from "@shared/primitives/table";
import { TextButton } from "@shared/primitives/text-button";

enum UsageColumnId {
  TYPE = "type",
  UNIT = "unit",
  USED = "used",
  VIEW = "view"
}

export const UsageOverviewPage: React.FC = () => {
  const accountId = getAccountId();
  const [hasLoadedData, setLoadedData] = React.useState(false);
  const [totalMinutes, setTotalMinutes] = React.useState(0);
  const [usedMinutes, setUsedMinutes] = React.useState(0);
  const [totalStorage, setTotalStorage] = React.useState(0);
  const [usedStorage, setUsedStorage] = React.useState(0);

  React.useEffect(() => {
    getUsageOverview({
      accountId
    }).then((response) => {
      setLoadedData(true);
      if (response) {
        setTotalMinutes(response.totalMinutes);
        setUsedMinutes(response.usedMinutes);
        setTotalStorage(response.totalStorageBytes);
        setUsedStorage(response.usedStorageBytes);
      }
    });
  }, []);

  if (!accountId || !config.features.usageStats) return;

  const items = [
    {
      item: "AI transcription & translation",
      unit: "Minutes",
      total: totalMinutes,
      usage: usedMinutes
    }
  ];

  return (
    <>
      <SettingsPageHeader title="Usage" description="Keep track of the usage of your account." className="tw-mb-6" />

      {!hasLoadedData ? (
        <div className="tw-flex tw-min-h-[300px] tw-items-center tw-justify-center">
          <Loader className="tw-mr-2" />
          <span>Loading...</span>
        </div>
      ) : (
        <>
          <StorageCard totalStorage={totalStorage} usedStorage={usedStorage} className="tw-mb-6 tw-min-w-100" />
          <h3 className="tw-mb-2 tw-flex tw-gap-1 tw-text-lg tw-font-semibold tw-text-neutral-900">Minutes used</h3>
          <Table
            columns={[
              { label: "Type", id: UsageColumnId.TYPE, width: "1fr" },
              { label: "Total", id: UsageColumnId.UNIT, width: "1fr" },
              { label: "Used", id: UsageColumnId.USED, width: "1fr" },
              { label: "", id: UsageColumnId.VIEW, width: "1fr" }
            ]}
            items={items}
            className="tw-flex tw-min-w-min tw-flex-col tw-overflow-auto"
          >
            <Table.Header className="tw-bg-neutral-50">
              {(cols) =>
                cols.map((col, i) => {
                  const isFirst = i === 0;
                  const isLast = cols.length - 1 === i;

                  return (
                    <Table.HeaderCell
                      key={col.id}
                      columnId={col.id}
                      className={classNames({
                        "!tw-pl-3": isFirst,
                        "!tw-pl-2": !isFirst,
                        "!tw-pr-3": isLast,
                        "!tw-pr-2": !isLast
                      })}
                    >
                      {col.label}
                    </Table.HeaderCell>
                  );
                })
              }
            </Table.Header>
            {items.map((item, i) => (
              <Row
                key={i}
                type={item.item}
                total={item.total}
                used={item.usage}
                link={SETTINGS_USAGE_TRANSACTIONS_PATH + "?reason=transcribe,translate"}
              />
            ))}
          </Table>
        </>
      )}
    </>
  );
};

interface MediaRowInnerProps {
  type: string;
  total: number;
  used: number;
  link: string;
}
const Row: React.FC<MediaRowInnerProps> = ({ type, total, used, link }) => {
  const navigate = useNavigate();
  const onClickCell = () => navigate(link);

  return (
    <Table.Row className="tw-min-h-12 tw-cursor-pointer hover:tw-bg-neutral-50">
      <Table.Cell columnId={UsageColumnId.TYPE} className="!tw-pl-3" onClick={onClickCell}>
        {type}
      </Table.Cell>
      <Table.Cell columnId={UsageColumnId.UNIT} onClick={onClickCell}>
        {total}
      </Table.Cell>
      <Table.Cell columnId={UsageColumnId.USED} onClick={onClickCell}>
        {used}
      </Table.Cell>
      <Table.Cell columnId={UsageColumnId.VIEW} className="!tw-pr-3" onClick={onClickCell}>
        <TextButton variant="secondary" size="sm" onClick={onClickCell} className="tw-ml-auto tw-mr-2 tw-w-[60px]">
          View
        </TextButton>
      </Table.Cell>
    </Table.Row>
  );
};
