import { format as formatTimestamp } from "@shared/utils/time";

type TimecodeProps = {
  timestamps: number[];
};

export const Timecode: React.FC<TimecodeProps> = (props) => {
  if (props.timestamps.length > 1) return "Multiple time stamps";
  const isOverHourLong = props.timestamps[0] >= 3600 * 1000;
  if (props.timestamps.length === 1) return formatTimestamp(props.timestamps[0], isOverHourLong);
  return "-";
};
