import React, { useState } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router";
import { v4 } from "uuid";

import { uploadFiles } from "@frontend/api/upload.service";
import { EN } from "@frontend/assets/i18n/en";
import { FilesList } from "@frontend/components/dashboard/upload-modal/files-list";
import { AccountInfo } from "@frontend/components/debug/account-info";
import settings from "@frontend/config/settings/settings";
import { useAuthProvider } from "@frontend/contexts/auth.context";
import { LOGIN_PATH, SETTINGS_BILLING_PATH } from "@frontend/routes";

import { InformationCircleIcon } from "@shared/components/icons";
import { useAccounts } from "@shared/hooks/use-accounts";
import { useAnalyticsWithAuth } from "@shared/hooks/use-analytics-with-auth";
import { useDashboard } from "@shared/hooks/use-dashboard";
import { useMediaInfo } from "@shared/hooks/use-media-info";
import { usePlan } from "@shared/hooks/use-plan";
import { usePlanPermissions } from "@shared/hooks/use-plan-permissions";
import { useQuery } from "@shared/hooks/use-query";
import { useUpload } from "@shared/hooks/use-upload";
import { OneDriveFile } from "@shared/interfaces/app";
import { SublyPlan } from "@shared/interfaces/billing";
import { Button } from "@shared/primitives/button";
import { notificationError } from "@shared/primitives/notification";
import { UploadFileType, UploadingStatus, uploadQueueStore } from "@shared/state/upload-queue";
import { formatMediaName } from "@shared/utils/strings";

import { ResourceType } from "@getsubly/common";
import { useRolePermissions } from "@getsubly/common/dist/permissions/use-role-permissions";

interface Params {
  state?: string;
  token?: string;
  id?: string;
  account?: string;
}

interface ParamState {
  activationParams: {
    items: string;
  };
  target: string;
}

export const OneDriveSummarisePage: React.FC = () => {
  const { queryParams } = useQuery<Params>();
  const { signOut } = useAuthProvider();
  const navigate = useNavigate();

  const [isScreenReady, setIsScreenReady] = useState(false);
  const { loading, checkFileErrors } = useMediaInfo();
  const { currentAccount, isLoaded, customVocabularyWords } = useAccounts();
  const { hasPermission: hasPlanPermission } = usePlanPermissions(SublyPlan.Pro);
  const { permissions: rolePermissions } = useRolePermissions(currentAccount?.role, ResourceType.Media);
  const { totalDuration, totalSize, stagedQueue, isCheckingFile } = useUpload();

  const { trackEventWithAuth } = useAnalyticsWithAuth();
  const { plan, isProOrHigher, availableSeconds, availableStorage } = usePlan();

  const { activeFolder } = useDashboard();
  const [folder, setFolder] = React.useState(activeFolder);
  React.useEffect(() => {
    if (!folder && activeFolder) setFolder(activeFolder);
  }, [activeFolder]);

  const hasInsufficientCredit = totalDuration > availableSeconds;
  const hasInsufficientStorage = totalSize > availableStorage;

  const disableUpload =
    !isScreenReady ||
    stagedQueue.filter((f) => !f.error).length === 0 ||
    loading ||
    hasInsufficientCredit ||
    hasInsufficientStorage ||
    !rolePermissions.Create;

  const processState = async (state: ParamState) => {
    try {
      const items = JSON.parse(state?.activationParams?.items) as string[]; // list of urls

      for (const url of items) {
        // const info = await getVideoPlatformFileInfo(url);
        const { data: info } = await axios.get<OneDriveFile>(url, {
          headers: {
            Authorization: `Bearer ${queryParams.token}`
          }
        });

        if (!info) {
          notificationError(EN.error.defaultMessage);
          return;
        }

        const duration = info.video.duration / 1000;
        const fileError = checkFileErrors({
          duration,
          size: info.size,
          skipDurationCheck: true
        });

        const mediaInfo = {
          duration,
          fileSize: info.size,
          isAudio: false
        };

        const mediaName = formatMediaName(info.name).slice(0, 129);

        const newFile: UploadFileType = {
          uploadId: v4(),
          mediaId: v4(),
          // folderId?: string;
          error: fileError,
          // warning?: string;
          mediaInfo,
          filename: info.name,
          mediaName,
          itemUrl: url,
          authorization: `Bearer ${queryParams.token}`,
          drive: info.parentReference.driveId,
          integration: queryParams.id,
          uploadPath: info.parentReference.path,
          uploadingStatus: UploadingStatus.Analyzing,
          uploadProgress: 0,
          url: `${url}/content`,
          isUrl: true
        };

        uploadQueueStore.addFilesToQueue([newFile], folder?.id);

        uploadQueueStore.updateFileInQueue(newFile.uploadId, {
          mediaInfo,
          uploadingStatus: UploadingStatus.Staged
        });
      }
    } catch (error) {
      console.error("ERROR:", error);
    } finally {
      setIsScreenReady(true);
    }
  };

  React.useEffect(() => {
    if (!queryParams.state || !isLoaded) {
      return;
    }

    const state = JSON.parse(atob(queryParams.state));
    processState(state);
  }, [queryParams.state, isLoaded]);

  React.useEffect(() => {
    if (!isLoaded || !queryParams.account || !currentAccount) {
      return;
    }

    if (currentAccount.accountId !== queryParams.account) {
      const alertMessage =
        "We couldn't find an account with those credentials. Please check your information and try again, or create a new account if you don't have one. If you believe this is an error, please contact your admin to request an invitation.";
      signOut(`${LOGIN_PATH}?alertMessage=${encodeURIComponent(alertMessage)}`);
    }
  }, [queryParams.account, currentAccount, isLoaded]);

  const handleChangeLanguage = (id: string, languageCode: string) => {
    uploadQueueStore.updateFileInQueue(id, { languageCode });
    uploadQueueStore.handleCheckLanguages();
  };

  const process = async () => {
    if (!hasPlanPermission || !rolePermissions.Create) return;

    uploadQueueStore.handleCheckLanguages();

    if (stagedQueue.some((f) => f.error)) {
      return;
    }

    const mediaIds = await uploadFiles({
      plan,
      isProOrHigher,
      mixpanelTrack: trackEventWithAuth,
      skipTranscription: false,
      accountCustomVocabulary: customVocabularyWords.map((word) => word.word),
      folderId: folder?.id,
      summariseAfterTranscribe: true
    });

    // navigate to processing page
    navigate(`/onedrive/process?state=${btoa(JSON.stringify({ mediaIds }))}&task=summary`);
  };

  return (
    <>
      <div className="tw-flex tw-w-[720px] tw-flex-col tw-gap-6 tw-rounded-8 tw-border tw-border-neutral-100 tw-bg-white tw-p-6">
        <div className="tw-flex tw-flex-col tw-gap-2">
          <p className="tw-text-lg tw-font-medium">Summarise</p>
          <p className="tw-text-md tw-text-neutral-600">Summarise this media.</p>
        </div>

        <div>
          <p className="tw-text-md tw-font-medium">Files</p>
          {!loading && isScreenReady && (
            <FilesList
              className="tw-my-2 tw-border tw-border-neutral-100 tw-bg-white"
              files={stagedQueue}
              isCheckingFile={isCheckingFile}
              showLanguagePicker
              // onClickPurchaseStorage={onClickPurchaseStorage}
              onChangeLanguage={handleChangeLanguage}
              languagePickerVariant="outline"
              languages={settings.transcription.languages}
            />
          )}
          {(loading || !isScreenReady) && <Skeleton height={40} />}
        </div>

        <div className="tw-flex tw-flex-row tw-justify-end">
          <Button variant="primary" size="32" onClick={process} disabled={disableUpload}>
            Proceed
          </Button>
        </div>

        {!loading && isLoaded && !hasPlanPermission && (
          <div className="tw-flex tw-flex-row tw-gap-2 tw-rounded-8 tw-bg-warning-100 tw-px-[10px] tw-py-3 tw-text-warning-900">
            <InformationCircleIcon className="tw-mt-0.5 tw-h-5 tw-w-5" />
            <div className="tw-flex tw-flex-col">
              <p className="tw-font-semibold">License Required</p>
              <p className="tw-font-medium">
                You need a license to perform this action.
                <br /> Please purchase one{" "}
                <a className="tw-text-primary-400" href={SETTINGS_BILLING_PATH}>
                  here
                </a>
                .
              </p>
            </div>
          </div>
        )}

        {!loading && isLoaded && hasPlanPermission && !rolePermissions.Create && (
          <div className="tw-flex tw-flex-row tw-gap-2 tw-rounded-8 tw-bg-warning-100 tw-px-[10px] tw-py-3 tw-text-warning-900">
            <InformationCircleIcon className="tw-mt-0.5 tw-h-5 tw-w-5" />
            <div className="tw-flex tw-flex-col">
              <p className="tw-font-semibold">Access Restricted</p>
              <p className="tw-font-medium">
                Your current role does not have permission to perform this action.
                <br /> Please contact your Subly admin to update your role.
              </p>
            </div>
          </div>
        )}
      </div>
      <AccountInfo className="tw-absolute tw-bottom-4 tw-right-4" />
    </>
  );
};
