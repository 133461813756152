import React from "react";
import classNames from "classnames";

import { AccessibilityReportModal } from "@frontend/components/modals/accessibility-report-modal/accessibility-report-modal";
import config from "@frontend/config";

import { useModal } from "@shared/hooks/use-modal";
import { ModalType } from "@shared/interfaces/modal-type";
import { Button, IconButton } from "@shared/primitives/button";
import {
  accessibilityAnalyserQueueQuery,
  accessibilityAnalyserQueueStore,
  AnalysingItem,
  AnalysingStatus
} from "@shared/state/accessibility-analysing-queue";

import { FileType } from "@getsubly/common";
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiCheckboxCircleFill,
  RiCloseCircleFill,
  RiCloseLine,
  RiErrorWarningFill,
  RiFileMusicFill,
  RiFilmLine,
  RiStopFill
} from "@remixicon/react";

import styles from "./dashboard-accessibility-analyser-queue.module.scss";

export const DashboardAccessibilityAnalyserQueue = () => {
  const [show, setShow] = React.useState(false);
  const [expanded, setExpanded] = React.useState(true);
  const [analysisQueue, setAnalysisQueue] = React.useState<AnalysingItem[]>([]);
  const [completedCount, setCompletedCount] = React.useState(0);
  const [errorCount, setErrorCount] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [isAllCompleted, setIsAllCompleted] = React.useState(false);
  const [hasErrors, setHasErrors] = React.useState(false);
  const [abortedCount, setAbortedCount] = React.useState(0);

  // Subscribe to store changes
  React.useEffect(() => {
    const queueSubscription = accessibilityAnalyserQueueQuery.selectQueue().subscribe((queue) => {
      setAnalysisQueue(queue);
      setAbortedCount(queue.filter((item) => item.analysingStatus === AnalysingStatus.Aborted).length);
    });
    const showSubscription = accessibilityAnalyserQueueQuery.selectShow().subscribe((showValue) => setShow(showValue)); // eslint-disable-line
    const expandedSubscription = accessibilityAnalyserQueueQuery.selectExpanded().subscribe((expandedValue) => setExpanded(expandedValue)); // eslint-disable-line
    const completedSubscription = accessibilityAnalyserQueueQuery.selectCompletedCount().subscribe((count) => setCompletedCount(count)); // eslint-disable-line
    const errorSubscription = accessibilityAnalyserQueueQuery.selectErrorCount().subscribe((count) => setErrorCount(count)); // eslint-disable-line
    const totalSubscription = accessibilityAnalyserQueueQuery.selectQueueLength().subscribe((count) => setTotalCount(count)); // eslint-disable-line
    const allCompletedSubscription = accessibilityAnalyserQueueQuery.selectIsAllCompleted().subscribe((completed) => setIsAllCompleted(completed)); // eslint-disable-line
    const hasErrorsSubscription = accessibilityAnalyserQueueQuery.selectHasErrors().subscribe((errors) => setHasErrors(errors)); // eslint-disable-line

    return () => {
      queueSubscription.unsubscribe();
      showSubscription.unsubscribe();
      expandedSubscription.unsubscribe();
      completedSubscription.unsubscribe();
      errorSubscription.unsubscribe();
      totalSubscription.unsubscribe();
      allCompletedSubscription.unsubscribe();
      hasErrorsSubscription.unsubscribe();
    };
  }, []);

  // Show this component whenever a new item is added to the queue
  React.useEffect(() => {
    const shouldShow = analysisQueue.length > 0;
    if (shouldShow && !show) {
      accessibilityAnalyserQueueStore.setShow(true);
    }
  }, [analysisQueue.length, show]);

  const canClose = React.useMemo(() => {
    const inProgressCount = totalCount - (completedCount + errorCount + abortedCount);
    return inProgressCount === 0 && totalCount > 0;
  }, [totalCount, completedCount, errorCount, abortedCount]);

  const getHeadingText = () => {
    const inProgressCount = totalCount - (completedCount + errorCount + abortedCount);

    if (!isAllCompleted) {
      // If there are no items in progress but we have aborted items and the analysis isn't complete
      if (inProgressCount === 0 && abortedCount > 0) {
        return "All items cancelled";
      }

      return `Analysing ${inProgressCount} of ${totalCount} media...`;
    }

    if (hasErrors && completedCount > 0) {
      return "Completed with some issues";
    } else if (hasErrors) {
      return "Analysis failed";
    } else {
      return "Analysis completed";
    }
  };

  const handleClose = () => {
    accessibilityAnalyserQueueStore.setShow(false);
    accessibilityAnalyserQueueStore.clearQueue();
  };

  return (
    <span
      className={classNames(
        "tw-flex tw-w-[438px] tw-translate-y-0 tw-transform tw-flex-col tw-overflow-hidden tw-rounded-md tw-border tw-border-neutral-200 tw-bg-white tw-opacity-100 tw-shadow-lg tw-transition-all",
        { "!tw-hidden tw-translate-y-full": !show }
      )}
    >
      <header className="tw-flex tw-w-full tw-border-b tw-border-b-neutral-200 tw-bg-neutral-100 tw-p-2.5">
        <span className="tw-ml-1 tw-mr-auto tw-text-sm tw-text-neutral-900">{getHeadingText()}</span>
        <IconButton
          variant="ghost"
          size="wrap"
          icon={
            expanded ? (
              <RiArrowDownSLine className="tw-h-5 tw-w-5 tw-shrink-0" />
            ) : (
              <RiArrowUpSLine className="tw-h-5 tw-w-5 tw-shrink-0" />
            )
          }
          className="tw-mt-0.5 tw-h-5 tw-w-5 tw-rounded-full"
          onClick={() => accessibilityAnalyserQueueStore.setExpanded(!expanded)}
        />
        <IconButton
          variant="ghost"
          size="wrap"
          icon={<RiCloseLine className="tw-h-5 tw-w-5 tw-shrink-0" />}
          className={classNames("tw-ml-3 tw-mt-0.5 tw-h-5 tw-w-5 tw-rounded-full", {
            "!tw-border-transparent !tw-bg-transparent": !canClose
          })}
          onClick={handleClose}
          disabled={!canClose}
        />
      </header>
      {expanded && (
        <ul className="tw-flex tw-max-h-[300px] tw-flex-col tw-overflow-y-auto">
          {analysisQueue.map((item) => (
            <QueuedItem
              key={item.mediaId}
              item={item}
              isComplete={item.analysingStatus === AnalysingStatus.Completed}
              hasError={item.analysingStatus === AnalysingStatus.Error}
              isAborted={item.analysingStatus === AnalysingStatus.Aborted}
              canAbort={config.isDevelopment}
            />
          ))}
        </ul>
      )}
    </span>
  );
};

type QueuedItemProps = {
  item: AnalysingItem;
  isComplete: boolean;
  hasError: boolean;
  isAborted: boolean;
  canAbort: boolean;
};

export const QueuedItem: React.FC<QueuedItemProps> = (props) => {
  const [showModal, hideModal] = useModal(ModalType.AccessibilityReport);

  const handleViewAccessibilityReport = async () => {
    showModal(<AccessibilityReportModal onDismiss={hideModal} media={props.item.media} />);
  };

  const handleAbort = (mediaId: string, event: React.MouseEvent) => {
    if (!props.canAbort) return;
    event.stopPropagation();
    accessibilityAnalyserQueueStore.abortAnalysis(mediaId);
  };

  // Item is interactive if completed or has error (but not aborted)
  const isInteractive = (props.isComplete || props.hasError) && !props.isAborted;
  const isAnalyzing = !props.isComplete && !props.hasError && !props.isAborted;

  return (
    <li key={props.item.mediaId} className="tw-flex tw-w-full">
      <div
        onClick={isInteractive ? handleViewAccessibilityReport : undefined}
        className={classNames("tw-flex tw-h-10 tw-w-full tw-items-center tw-gap-2 tw-p-2 tw-px-3", {
          "tw-cursor-pointer hover:tw-bg-neutral-50": isInteractive
        })}
      >
        <div className="tw-inline-flex tw-shrink-0 tw-items-center tw-justify-center">
          {props.isComplete && <RiCheckboxCircleFill className="tw-h-5 tw-w-5 tw-shrink-0 tw-text-success-500" />}
          {props.hasError && (
            <RiErrorWarningFill className="tw-text-error-500 tw-h-5 tw-w-5 tw-shrink-0 tw-text-destructive-500" />
          )}
          {(isAnalyzing || props.isAborted) && (
            <div className="tw-group tw-inline-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center">
              <div
                className={classNames(
                  "tw-relative tw-flex tw-h-[17px] tw-w-[17px] tw-rounded-full tw-border-2 tw-p-[3px]",
                  {
                    "tw-border-primary-600 tw-text-primary-600": isAnalyzing,
                    "tw-cursor-pointer": props.canAbort,
                    "tw-border-neutral-600 tw-text-neutral-600 tw-opacity-50": props.isAborted
                  }
                )}
                onClick={(e) => isAnalyzing && props.canAbort && handleAbort(props.item.mediaId, e)}
              >
                {props.isAborted ? (
                  <RiCloseCircleFill className="tw-absolute tw-left-[-2px] tw-top-[-2px] tw-h-[17px] tw-w-[17px]" />
                ) : (
                  <>
                    <div
                      className={classNames(styles.bars, {
                        "group-hover:!tw-hidden": props.canAbort
                      })}
                    />
                    <RiStopFill
                      className={classNames("tw-absolute tw-left-[1px] tw-top-[1px] tw-h-[11px] tw-w-[11px]", {
                        "tw-hidden group-hover:!tw-block": props.canAbort,
                        "tw-hidden": !props.canAbort
                      })}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </div>
        <FeaturedIcon disabled={!isInteractive} type={props.item.media.type} />
        <span
          className={classNames(
            "tw-flex-1 tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-text-sm tw-font-normal",
            { "tw-opacity-50": !isInteractive }
          )}
        >
          {props.item.media.name}
        </span>
        {isInteractive && (
          <Button size="20" variant="secondary" className="tw-cursor-pointer tw-px-1.5">
            View
          </Button>
        )}
      </div>
    </li>
  );
};

type FeaturedIconProps = {
  disabled?: boolean;
  type: FileType;
};

const FeaturedIcon: React.FC<FeaturedIconProps> = ({ disabled, type }) => {
  return (
    <div className="tw-inline-flex tw-h-4 tw-w-4 tw-items-center tw-justify-center tw-rounded tw-bg-neutral-50 tw-text-neutral-600">
      {type === "VIDEO" && (
        <RiFilmLine
          className={classNames("tw-h-4 tw-w-4 tw-shrink-0", {
            "tw-opacity-50": disabled
          })}
        />
      )}
      {type === "AUDIO" && (
        <RiFileMusicFill
          className={classNames("tw-h-4 tw-w-4 tw-shrink-0", {
            "tw-opacity-50": disabled
          })}
        />
      )}
    </div>
  );
};
