import React, { useCallback } from "react";
import classNames from "classnames";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import { abortAccessibilityReport, deleteMedia } from "@frontend/api/media.service";
import { loadMediaEditor } from "@frontend/api/media-editor.service";
import { MediaListSortColumn } from "@frontend/api/rest-api";
import { EN } from "@frontend/assets/i18n/en";
import { AccessibilityReportModal } from "@frontend/components/modals/accessibility-report-modal/accessibility-report-modal";
import { DeleteMediaModal } from "@frontend/components/modals/delete-media-modal";
import { DownloadMediaModal } from "@frontend/components/modals/download-media-modal";
import { DuplicateMediaModal } from "@frontend/components/modals/duplicate-media-modal";
import { MoveMediaModal } from "@frontend/components/modals/move-media-modal";
import { RenameMediaModal } from "@frontend/components/modals/rename-media-modal";
import config from "@frontend/config";
import { useSelectedFiles } from "@frontend/contexts/selected-files.context";
import { MEDIA_PATH } from "@frontend/routes";

import { AudioIcon, InformationCircleIcon } from "@shared/components/icons";
import { useAccounts } from "@shared/hooks/use-accounts";
import { useModal } from "@shared/hooks/use-modal";
import { useQuery } from "@shared/hooks/use-query";
import { EnrichedMediaListItem, MediaErrorReason, MediaListItem } from "@shared/interfaces/media";
import { ModalType } from "@shared/interfaces/modal-type";
import { Button } from "@shared/primitives/button";
import { Dropdown, DropdownActionIconButton, DropdownItem, DropdownMenu } from "@shared/primitives/dropdown";
import { FlagGroup } from "@shared/primitives/flag-group/flag-group";
import { Loader } from "@shared/primitives/loader";
import { notificationError } from "@shared/primitives/notification";
import { Table } from "@shared/primitives/table";
import { StickyPosition } from "@shared/primitives/table/table.context";
import { ToolTip } from "@shared/primitives/tooltip";
import { WcagBadge } from "@shared/primitives/wcag-badge/wcag-badge";
import { editorStateRepository } from "@shared/state/editor/editor.state";
import { mediaHistoryRepository } from "@shared/state/editor-history/editor-history.state";
import { parseCreditToText } from "@shared/utils/plans";
import { formatBytes } from "@shared/utils/storage-size";

import {
  languageLookup,
  MediaStatus,
  ResourceType,
  RoleName,
  transcriptionLanguages,
  translationLanguages
} from "@getsubly/common";
import { useRolePermissions } from "@getsubly/common/dist/permissions/use-role-permissions";
import { mediaEditorStateRepository } from "@media-editor/state/media-editor.state";
import {
  RiCloseLine,
  RiDeleteBinLine,
  RiDownloadLine,
  RiErrorWarningFill,
  RiFileCopyLine,
  RiFilmFill,
  RiMoreLine,
  RiPencilLine,
  RiScanLine,
  RiShareForwardLine
} from "@remixicon/react";

enum MediaColumnId {
  CHECKBOX = "checkbox",
  FILENAME = "filename",
  LANGUAGE = "language",
  TRANSCRIPTION = "transcription",
  SUBTITLES = "subtitles",
  AUDIO_DESCRIPTION = "audiodescription",
  WCAG_COMPLIANCE = "WCAGcompliance",
  SIZE = "size",
  LENGTH = "length",
  CREATED = "created",
  OTHER = "other"
}

const MEDIA_TABLE_COLUMNS = [
  { id: MediaColumnId.FILENAME, label: "File name", width: "1fr", sortable: true, sortColumn: MediaListSortColumn.NAME }, // eslint-disable-line
  { id: MediaColumnId.LANGUAGE, label: "Language", width: "1fr", sortable: true, sortColumn: MediaListSortColumn.LANGUAGE }, // eslint-disable-line
  { id: MediaColumnId.TRANSCRIPTION, label: "Transcription", width: "1fr" }, // eslint-disable-line
  { id: MediaColumnId.SUBTITLES, label: "Subtitles", width: "1fr" }, // eslint-disable-line
  { id: MediaColumnId.AUDIO_DESCRIPTION, label: "Audio Desc.", width: "1fr" }, // eslint-disable-line
  { id: MediaColumnId.WCAG_COMPLIANCE, label: "Compliance", width: "1fr", sortable: true, invertSort: true, sortColumn: MediaListSortColumn.WCAG_OVERALL_COMPLIANCE, tooltip: 'This column shows the media’s accessibility rating: AAA (fully accessible), AA (key requirements met), A (basic compliance), Non-Compliant (fixes needed). Based on the latest report; not updated automatically after fixes.' }, // eslint-disable-line
  { id: MediaColumnId.LENGTH, label: "Length", width: "1fr", sortable: true, sortColumn: MediaListSortColumn.LENGTH }, // eslint-disable-line
  { id: MediaColumnId.SIZE, label: "Size", width: "1fr", sortable: true, sortColumn: MediaListSortColumn.SIZE }, // eslint-disable-line
  { id: MediaColumnId.CREATED, label: "Created", width: "min-content", sortable: true, sortColumn: MediaListSortColumn.CREATED }, // eslint-disable-line
  { id: MediaColumnId.OTHER, label: "", width: "min-content", sticky: StickyPosition.RIGHT } // eslint-disable-line
];

const useMediaLanguages = (media: EnrichedMediaListItem) => {
  return React.useMemo(() => {
    const lookupLanguages = [...transcriptionLanguages, ...translationLanguages];

    const mapLanguages = (codes: string[] = []) =>
      codes
        .map((code) => languageLookup(lookupLanguages, code))
        .filter(Boolean)
        .map((lang) => ({ flagCode: lang!.flagCode, label: lang!.language }));

    return {
      mediaLanguage: media.languageCode ? mapLanguages([media.languageCode])[0] : null,
      audioDescriptionLanguage: media.audioDescriptionLanguageCode
        ? mapLanguages([media.audioDescriptionLanguageCode])[0]
        : null,
      subtitleLanguages: mapLanguages(media.subtitleLanguageCodes),
      transcriptionLanguages: mapLanguages(media.transcriptionLanguageCodes)
    };
  }, [media.languageCode, media.subtitleLanguageCodes, media.transcriptionLanguageCodes]);
};

interface MediaTableProps {
  mediaList: EnrichedMediaListItem[];
}
export const MediaTable: React.FC<MediaTableProps> = ({ mediaList }) => {
  const { selectedFiles, setSelectedFiles } = useSelectedFiles();
  const { queryParams, updateQueryParams } = useQuery();

  const handleClickSort = (clickedSort: MediaListSortColumn) => {
    if (clickedSort !== queryParams.sort) {
      updateQueryParams({ sort: clickedSort, order: "desc" });
    } else {
      updateQueryParams({ order: queryParams.order === "asc" ? "desc" : "asc" });
    }
  };

  const selectableMediaList = React.useMemo(() => mediaList.filter((media) => !media.isTranscribing), [mediaList]);

  const checkboxChecked = React.useMemo(
    () => selectedFiles.length === selectableMediaList.length && selectableMediaList.length > 0,
    [selectedFiles, selectableMediaList]
  );

  const checkboxIndeterminate = React.useMemo(
    () => selectedFiles.length > 0 && selectedFiles.length < selectableMediaList.length,
    [selectedFiles, selectableMediaList]
  );

  const handleSelectAll = useCallback(() => {
    setSelectedFiles(!checkboxChecked ? selectableMediaList.map((media) => media.mediaId) : []);
  }, [checkboxChecked, selectableMediaList, setSelectedFiles]);

  if (!mediaList.length) return null;

  return (
    <Table
      columns={MEDIA_TABLE_COLUMNS}
      items={mediaList}
      className="tw-flex tw-min-w-0 tw-flex-col tw-overflow-auto tw-whitespace-nowrap"
    >
      <Table.Header className="tw-bg-neutral-50">
        {(cols) =>
          cols.map((col) => {
            if (col.id === MediaColumnId.FILENAME) {
              return (
                <Table.HeaderCell
                  key={col.id}
                  columnId={col.id}
                  checkable
                  checkboxChecked={checkboxChecked}
                  checkboxIndeterminate={checkboxIndeterminate}
                  onChangeCheckbox={handleSelectAll}
                  sortable={col.sortable}
                  invertSort={col.invertSort}
                  isSorting={col.sortColumn === queryParams.sort}
                  sortDirection={queryParams.order}
                  onClickSort={() => col.sortColumn && handleClickSort(col.sortColumn as MediaListSortColumn)}
                  tooltip={col.tooltip}
                >
                  {col.label}
                </Table.HeaderCell>
              );
            }
            return (
              <Table.HeaderCell
                key={col.id}
                columnId={col.id}
                sortable={col.sortable}
                invertSort={col.invertSort}
                isSorting={col.sortColumn === queryParams.sort}
                sortDirection={queryParams.order}
                onClickSort={() => col.sortColumn && handleClickSort(col.sortColumn as MediaListSortColumn)}
                className={classNames({ "tw-justify-center": col.id === MediaColumnId.WCAG_COMPLIANCE })}
                tooltip={col.tooltip}
              >
                {col.label}
              </Table.HeaderCell>
            );
          })
        }
      </Table.Header>
      {mediaList.map((media, i) => (
        <MediaRow media={media} key={i} />
      ))}
    </Table>
  );
};

interface MediaRowProps {
  media: EnrichedMediaListItem;
}
const MediaRow: React.FC<MediaRowProps> = ({ media }) => {
  const { selectedFiles, setSelectedFiles } = useSelectedFiles();
  const isSelected = selectedFiles.includes(media.mediaId);
  const navigate = useNavigate();

  const mediaLink = `${MEDIA_PATH}/${media.mediaId}`;

  const handleSelectCheckbox = useCallback(() => {
    setSelectedFiles((ids) => (isSelected ? ids.filter((id) => id !== media.mediaId) : [...ids, media.mediaId]));
  }, [isSelected, media.mediaId, setSelectedFiles]);

  const [deleting, setDeleting] = React.useState(false);

  const handleDelete = async (event: Event) => {
    event.stopPropagation();
    try {
      setDeleting(true);
      await deleteMedia(media.mediaId);
    } catch (e) {
      notificationError(EN.error.defaultMessage);
    } finally {
      setDeleting(false);
    }
  };

  const flagGroupLanguages = useMediaLanguages(media);

  const hasUploadFailedError = React.useMemo(() => media.status === MediaStatus.Failed, [media.status]);
  const hasMediaLostError = React.useMemo(() => media.s3_file_is_missing, [media.s3_file_is_missing]);

  const hasError = React.useMemo(() => {
    return hasUploadFailedError || hasMediaLostError;
  }, [hasUploadFailedError, hasMediaLostError]);

  const errorMessage = React.useMemo(() => {
    if (hasUploadFailedError) {
      return media.reason === MediaErrorReason.UploadCancelled
        ? EN.media[MediaErrorReason.UploadCancelled]
        : EN.media[MediaErrorReason.ProcessingCancelled];
    }
    if (hasMediaLostError) {
      return EN.media[MediaErrorReason.MediaLost];
    }
    return null;
  }, [hasUploadFailedError, hasMediaLostError]);

  const rowClasses = React.useMemo(() => {
    return classNames("tw-cursor-pointer tw-min-h-12", {
      "!tw-bg-primary-50": isSelected,
      "hover:!tw-bg-neutral-50": !isSelected,
      "!tw-cursor-not-allowed": hasMediaLostError
    });
  }, [isSelected]);

  const { size, units } = formatBytes(media.storageBytes ?? 0, 2);

  const [showModal, hideModal] = useModal(ModalType.AccessibilityReport);

  const handleViewAccessibilityReport = async () => {
    showModal(<AccessibilityReportModal onDismiss={hideModal} media={media} />);
  };

  const handleCancelAccessibilityReport = async (media: MediaListItem) => {
    abortAccessibilityReport(media.mediaId);
  };

  const handleClickRow = (e: React.MouseEvent<HTMLDivElement>) => {
    if (hasMediaLostError) return;
    if ((e.target as HTMLElement)?.closest("[data-prevent-navigate]")) return;
    if ((e.target as HTMLElement)?.closest("[data-table-checkbox]")) return;
    navigate(mediaLink);
  };

  const { currentAccount } = useAccounts();

  return (
    <Table.Row className={rowClasses} onClick={handleClickRow}>
      <Table.Cell
        className={classNames("tw-min-w-[200px] !tw-gap-2 tw-overflow-hidden tw-font-medium", {
          "tw-col-start-1 tw-col-end-7": hasError
        })}
        columnId={MediaColumnId.FILENAME}
        checkable
        checkboxChecked={isSelected}
        checkboxDisabled={Boolean(media.isTranscribing)}
        onCheckboxChange={handleSelectCheckbox}
      >
        <div
          className={classNames(
            "tw-inline-flex tw-h-6 tw-w-6 tw-shrink-0 tw-items-center tw-justify-center tw-rounded",
            { "tw-bg-destructive-50": hasError, "tw-bg-neutral-50": !hasError }
          )}
        >
          {!hasError && (
            <>
              {media.type === "VIDEO" && <RiFilmFill className="tw-h-4 tw-w-4 tw-shrink-0" />}
              {media.type === "AUDIO" && <AudioIcon className="tw-h-4 tw-w-4 tw-shrink-0" />}
            </>
          )}
          {hasError && <RiErrorWarningFill className="tw-h-4 tw-w-4 tw-text-destructive-600" />}
        </div>
        <div title={media.name} className="tw-overflow-hidden">
          <span className="tw-block tw-overflow-hidden tw-text-ellipsis tw-text-neutral-700">{media.name}</span>
          {hasError && (
            <div className="tw-text-pretty tw-text-xs tw-font-medium tw-text-destructive-600">{errorMessage}</div>
          )}
        </div>
      </Table.Cell>
      {!hasError && (
        <Table.Cell columnId={MediaColumnId.LANGUAGE}>
          {flagGroupLanguages.mediaLanguage ? <FlagGroup flags={[flagGroupLanguages.mediaLanguage]} /> : "-"}
        </Table.Cell>
      )}
      {!hasError && (
        <Table.Cell columnId={MediaColumnId.TRANSCRIPTION}>
          {flagGroupLanguages.transcriptionLanguages.length ? (
            <FlagGroup flags={flagGroupLanguages.transcriptionLanguages} />
          ) : (
            "-"
          )}
        </Table.Cell>
      )}
      {!hasError && (
        <Table.Cell className="tw-flex-wrap" columnId={MediaColumnId.SUBTITLES}>
          {flagGroupLanguages.subtitleLanguages.length ? (
            <FlagGroup flags={flagGroupLanguages.subtitleLanguages} />
          ) : (
            "-"
          )}
        </Table.Cell>
      )}
      {!hasError && (
        <Table.Cell className="tw-flex-wrap" columnId={MediaColumnId.AUDIO_DESCRIPTION}>
          {flagGroupLanguages.audioDescriptionLanguage ? (
            <FlagGroup flags={[flagGroupLanguages.audioDescriptionLanguage]} />
          ) : (
            "-"
          )}
        </Table.Cell>
      )}
      {!hasError && (
        <Table.Cell className="tw-flex-wrap tw-justify-center tw-pr-[28px]" columnId={MediaColumnId.WCAG_COMPLIANCE}>
          {media.accessibilityReport?.status === "complete" && media.accessibilityReport?.WCAGOverallCompliance ? (
            <WcagBadge wcagLevel={media.accessibilityReport.WCAGOverallCompliance} />
          ) : (
            "-"
          )}
        </Table.Cell>
      )}
      <Table.Cell className="tw-whitespace-nowrap" columnId={MediaColumnId.LENGTH}>
        {parseCreditToText(media.duration ?? 0)}
      </Table.Cell>
      <Table.Cell className="tw-whitespace-nowrap" columnId={MediaColumnId.SIZE}>
        {media?.storageBytes ? `${size} ${units}` : "-"}
      </Table.Cell>
      <Table.Cell columnId={MediaColumnId.CREATED} className="tw-whitespace-nowrap">
        <span title={format(new Date(media.createdAt), "dd MMM yyyy hh:mm:ss")}>
          {format(new Date(media.createdAt), "dd MMM yyyy")}
        </span>
        <div className="tw-ml-5 tw-font-medium tw-text-neutral-700">
          {media.isAiTranscribing && (
            <span className="tw-inline-flex tw-w-full tw-items-center tw-justify-between tw-gap-2">
              Transcribing...
            </span>
          )}
          {media.isHumanTranscribing && (
            <div className="tw-inline-flex tw-items-center tw-gap-1">
              <span className="tw-whitespace-nowrap">Human transcribing...</span>
              <ToolTip
                text={
                  <div>
                    Your file is being transcribed by a professional transcriber.
                    <br />
                    We will send you an email as soon as your transcription is completed.
                  </div>
                }
                place="top"
              >
                <InformationCircleIcon className="tw-cursor-pointer tw-stroke-neutral-700" width={16} height={16} />
              </ToolTip>
            </div>
          )}
          {!media.isTranscribing && media.accessibilityReport?.status === "analysing" && (
            <span className="tw-inline-flex tw-w-full tw-items-center tw-justify-between tw-gap-2">Analysing...</span>
          )}
        </div>
      </Table.Cell>
      <Table.Cell
        columnId={MediaColumnId.OTHER}
        className="tw-sticky tw-right-0 tw-min-w-0 tw-cursor-default tw-justify-center !tw-opacity-100"
        data-prevent-navigate
      >
        {config.features.accessibilityReport &&
          !hasError &&
          currentAccount?.role &&
          currentAccount?.role !== RoleName.Viewer && (
            <Button
              variant="secondary"
              size="24"
              onClick={(e) => {
                e.stopPropagation();
                handleViewAccessibilityReport();
              }}
              disabled={
                media.isTranscribing || selectedFiles.length > 0 || media.accessibilityReport?.status === "analysing"
              }
              className="tw-float-right"
            >
              {media.accessibilityReport?.status === "complete" ? "View analysis" : "Analyse accessibility"}
            </Button>
          )}

        <>
          {media.isTranscribing && (
            <div className="tw-inline-flex tw-h-7 tw-w-7 tw-shrink-0 tw-items-center tw-justify-center">
              <Loader className="tw-mr-[3px] tw-h-[14px] tw-w-[14px]" />
            </div>
          )}
          {!media.isTranscribing && !hasError && (
            <MediaMenu
              media={media}
              onViewAccessibilityReport={handleViewAccessibilityReport}
              onCancelAccessibilityReport={handleCancelAccessibilityReport}
              disabled={selectedFiles.length > 0}
            />
          )}
          {!media.isTranscribing && hasError && (
            <Button
              variant="destructive"
              size="24"
              onClick={handleDelete}
              loading={deleting}
              className="tw-float-right"
              disabled={selectedFiles.length > 0}
            >
              Delete
            </Button>
          )}
        </>
      </Table.Cell>
    </Table.Row>
  );
};

interface MediaMenuProps {
  media: MediaListItem;
  disabled: boolean;
  onViewAccessibilityReport: () => void;
  onCancelAccessibilityReport: (media: MediaListItem) => void;
}

const MediaMenu: React.FC<MediaMenuProps> = ({
  media,
  disabled,
  onViewAccessibilityReport,
  onCancelAccessibilityReport
}) => {
  const [loading, setLoading] = React.useState(false);
  const [showMoveModal, hideMoveModal] = useModal(ModalType.MoveMedia);
  const [showDeleteModal, hideDeleteModal] = useModal(ModalType.DeleteMedia);
  const [showRenameModal, hideRenameModal] = useModal(ModalType.RenameMedia);
  const [showDuplicateModal, hideDuplicateModal] = useModal(ModalType.DuplicateMedia);
  const [showDownloadModal, hideDownloadModal] = useModal(ModalType.DownloadModal);

  const handleDownload = async (mediaId: string) => {
    try {
      const editorLoading = editorStateRepository.editorLoading;

      if (editorLoading) {
        return;
      }

      setLoading(true);

      const { assConfig, transcriptions } = await loadMediaEditor(mediaId);

      mediaEditorStateRepository.updateState({ transcriptions });

      mediaHistoryRepository.initState(transcriptions, assConfig);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
    showDownloadModal(<DownloadMediaModal closeModal={hideDownloadModal} />);
  };

  const handleRename = (media: MediaListItem) => {
    showRenameModal(
      <RenameMediaModal
        mediaId={media.mediaId}
        mediaName={media.name}
        mediaLanguage={media.languageCode}
        hideModal={hideRenameModal}
      />
    );
  };

  const handleMove = (media: MediaListItem) => {
    showMoveModal(<MoveMediaModal mediaIds={[media.mediaId]} closeModal={hideMoveModal} />);
  };

  const handleDuplicate = (media: MediaListItem) => {
    showDuplicateModal(
      <DuplicateMediaModal
        closeModal={hideDuplicateModal}
        mediaId={media.mediaId}
        mediaName={media.name}
        mediaFolderId={media.folderId}
        mediaFileSize={media.storageBytes}
      />
    );
  };

  const handleDelete = useCallback(async (media: MediaListItem) => {
    try {
      showDeleteModal(
        <DeleteMediaModal
          mediaIds={[media.mediaId]}
          onBeforeDelete={() => setLoading(true)}
          onAfterDelete={() => setLoading(false)}
          closeModal={() => hideDeleteModal()}
        />
      );
    } catch (e) {
      notificationError(EN.error.defaultMessage);
    }
  }, []);

  const { currentAccount } = useAccounts();
  const { permissions: mediaPermissions } = useRolePermissions(currentAccount?.role, ResourceType.Media);

  const canPerformActions = React.useMemo(
    () => ({
      rename: mediaPermissions.Update,
      move: mediaPermissions.Move,
      download: mediaPermissions.Download && config.features.showDownloadFromDashboard,
      duplicate: mediaPermissions.Create,
      delete: mediaPermissions.Delete,
      assessAccessibility: mediaPermissions.Analyse
    }),
    [mediaPermissions]
  );

  const menuActions = React.useMemo(
    () =>
      [
        {
          label: "Accessibility report",
          show: config.features.accessibilityReport && canPerformActions.assessAccessibility,
          disabled: media.accessibilityReport?.status === "analysing",
          icon: RiScanLine,
          onClick: onViewAccessibilityReport
        },
        {
          label: "Cancel accessibility report",
          show:
            config.features.accessibilityReport &&
            canPerformActions.assessAccessibility &&
            config.isDevelopment &&
            media.accessibilityReport?.status === "analysing",
          icon: RiCloseLine,
          onClick: () => onCancelAccessibilityReport(media)
        },
        {
          label: "Download",
          show: canPerformActions.download,
          icon: RiDownloadLine,
          onClick: () => handleDownload(media.mediaId)
        },
        {
          label: "Rename",
          show: canPerformActions.rename,
          icon: RiPencilLine,
          onClick: () => handleRename(media)
        },
        {
          label: "Move...",
          show: canPerformActions.move,
          icon: RiShareForwardLine,
          onClick: () => handleMove(media)
        },
        {
          label: "Duplicate",
          show: canPerformActions.duplicate,
          icon: RiFileCopyLine,
          onClick: () => handleDuplicate(media)
        },
        {
          label: "Delete",
          show: canPerformActions.delete,
          icon: RiDeleteBinLine,
          onClick: () => handleDelete(media),
          destructive: true
        }
      ].filter((item) => item.show),
    [canPerformActions, media]
  );

  if (!currentAccount?.role || currentAccount?.role === RoleName.Viewer) {
    return;
  }

  return (
    <Dropdown>
      <DropdownActionIconButton
        size="20"
        variant="for-white-background"
        icon={<RiMoreLine />}
        loading={loading}
        className={classNames({ "!tw-border-none !tw-bg-transparent": loading }, "!tw-h-7 !tw-w-7")}
        disabled={disabled}
      />
      <DropdownMenu className="tw-min-w-[160px]" placement="bottom-start" showInPortal>
        {menuActions.map((action) => (
          <DropdownItem
            key={action.label}
            onClick={action.onClick}
            className={classNames(
              "tw-flex tw-flex-row tw-items-center tw-gap-2 !tw-text-sm",
              action.destructive && "!tw-text-destructive-600"
            )}
            lIcon={
              <action.icon
                className={classNames(
                  "tw-h-5 tw-w-5 tw-text-[#6f717c]",
                  action.destructive && "!tw-text-destructive-600"
                )}
              />
            }
            disabled={action.disabled}
          >
            {action.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};
