import React from "react";

import { NewModal } from "@shared/primitives/new-modal";

interface AlertProps {
  title?: string;
  description?: string | React.ReactNode;
  cancelButtonText?: string;
  confirmButtonText?: string;
  isWarning?: boolean;
  isVerticallyCentred?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
}
export const AlertModal: React.FC<AlertProps> = ({
  title,
  description,
  cancelButtonText,
  confirmButtonText,
  isWarning,
  isVerticallyCentred,
  onCancel,
  onConfirm
}) => {
  return (
    <NewModal
      onDismiss={onCancel}
      onClickPrimary={onConfirm}
      onClickSecondary={onCancel}
      title={title}
      description={description}
      secondaryAction={cancelButtonText}
      primaryAction={confirmButtonText}
      isPrimaryWarning={isWarning}
      isVerticallyCentred={isVerticallyCentred}
    />
  );
};
