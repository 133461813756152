import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { createZendeskTicket } from "@frontend/api/zendesk.service";
import config from "@frontend/config";
import { SETTINGS_BILLING_PATH } from "@frontend/routes";

import { usePlan } from "@shared/hooks/use-plan";
import { useUser } from "@shared/hooks/use-user";
import { EnrichedMediaListItem } from "@shared/interfaces/media";
import { NewModal } from "@shared/primitives/new-modal";
import { notificationError, notificationSuccess } from "@shared/primitives/notification";
import { Table } from "@shared/primitives/table";
import { getCreditMinutes, parseCreditToText } from "@shared/utils/plans";

import { RiErrorWarningFill } from "@remixicon/react";

import { FlagAndLanguage } from "../components/flag-and-language";
import { getCost } from "../pricing";

interface AccessibilityReportSplashViewModalProps {
  onClickAnalyse: () => void;
  onClickTalkToUs: () => void;
  onClickViewSampleData: () => void;
  onDismiss: () => void;
  media: EnrichedMediaListItem;
}

export const AccessibilityReportSplashViewModal: React.FC<AccessibilityReportSplashViewModalProps> = (props) => {
  const { user } = useUser();

  // Calculate cost based on plan type
  const { availableSeconds, isBusiness, isEnterprise, isTrial } = usePlan();
  const minutesLeft = getCreditMinutes(availableSeconds);
  const mediaDurationSeconds = props.media.duration || 0;

  // Calculate analysis cost based on plan type
  const analysisCost = getCost({ isEnterprise, mediaDurationSeconds });

  // Only show cost text for paid plans
  const hasPermission = !isTrial && (isBusiness || isEnterprise);
  const footerText = hasPermission ? `Cost: ${analysisCost} minutes` : "";

  const handleTalkToUs = async () => {
    try {
      const subject = config.isProduction
        ? `[Analyse Accessibility request] for ${user?.email}`
        : `[DEV - Analyse Accessibility request] for ${user?.email}`;

      await createZendeskTicket({
        subject,
        body: `
        This is an automated message:

        User ${user?.name || user?.email} has requested information about the Analyse Accessibility Report feature.

        The user clicked "Talk to us" as this feature is not available on their current plan.

        Please contact the user to discuss their options for accessing this feature.
      `,
        requester: { name: user?.name, email: user?.email }
      });

      notificationSuccess("You have successfully contacted sales, we will get back to you shortly.");
      props.onDismiss();
    } catch (err) {
      notificationError(err?.message);
    }
  };

  return (
    <NewModal
      title={"Analyse Accessibility for " + props.media.name}
      description="This check will analyse your video against WCAG accessibility standards"
      showCloseButton
      primaryAction={hasPermission ? "Analyse accessibility" : "Talk to us"}
      onClickPrimary={hasPermission ? props.onClickAnalyse : handleTalkToUs}
      secondaryAction={hasPermission ? undefined : "Sample report"}
      onClickSecondary={props.onClickViewSampleData}
      tertiaryAction="Close"
      onClickTertiary={props.onDismiss}
      onDismiss={props.onDismiss}
      footerText={footerText}
      size="930"
      className="tw-max-w-[568px] min-[978px]:tw-max-w-[930px]"
    >
      <div className="tw-my-4 tw-min-h-0 tw-flex-1 tw-overflow-y-auto">
        <div className="tw-mb-6 tw-flex tw-justify-between tw-text-sm">
          <span className="tw-font-normal tw-text-neutral-600">
            Availability: <strong className="tw-font-medium tw-text-neutral-900">{minutesLeft} minutes</strong>
          </span>
          <span className="tw-font-normal tw-text-neutral-600">
            Cost: <strong className="tw-font-medium tw-text-neutral-900">{analysisCost} minutes</strong>
          </span>
        </div>
        {!hasPermission && (
          <div className="tw-mb-4 tw-flex tw-items-center tw-gap-2 tw-rounded tw-bg-warning-100 tw-p-3 tw-text-xs tw-text-warning-700">
            <RiErrorWarningFill className="tw-h-4 tw-w-4 tw-min-w-4" />
            <div>
              Accessibility reports are not available on your plan. Please{" "}
              <Link
                to={{ pathname: SETTINGS_BILLING_PATH, search: "checkout=true" }}
                className="tw-text-primary-500 hover:tw-text-primary-500 hover:tw-underline"
                onClick={props.onDismiss}
              >
                upgrade your plan
              </Link>
            </div>
          </div>
        )}
        <InputFilesTable media={props.media} />
        <h3 className="tw-mb-0.5 tw-text-lg tw-font-medium">What will be analysed?</h3>
        <p className="tw-mb-5 tw-text-sm tw-text-neutral-500">
          This analysis checks key accessibility features based on WCAG standards:
        </p>
        <ul className="tw-mb-4 tw-flex tw-list-inside tw-list-disc tw-flex-col tw-gap-4 tw-text-sm">
          <li>
            Captions → Open and closed captions (including burned-in), checking for readability (CPS/CPL) and contrast.
          </li>
          <li>Flashing Content → Detects seizure-triggering flashes (&gt;3 per second).</li>
          <li>Contrast & Readability → Checks if burned-in captions meet contrast standards.</li>
          <li>Multiple Subtitle Files → Analyses compliance for all subtitle files in different languages.</li>
        </ul>
      </div>
    </NewModal>
  );
};

type InputFilesTable = {
  media: EnrichedMediaListItem;
};

export const InputFilesTable: React.FC<InputFilesTable> = (props) => {
  const [length] = React.useMemo(() => {
    const length = parseCreditToText(props.media.duration ?? 0);
    return [length];
  }, [props.media.languageCode, props.media.duration]);

  return (
    <Table
      className="tw-mb-6 tw-flex tw-w-full tw-overflow-auto"
      columns={[
        { id: "file-name", label: "Media name", width: "auto" },
        { id: "file-duration", label: "Duration", width: "auto" },
        { id: "spoken-language", label: "Spoken language", width: "auto" }
      ]}
      items={[]}
    >
      <Table.Header className="tw-sticky tw-top-0 tw--mb-px !tw-h-9 !tw-min-h-9 tw-overflow-hidden tw-whitespace-nowrap tw-rounded-t-7 tw-border-b tw-border-neutral-200 tw-bg-neutral-50">
        {(cols) =>
          cols.map((col) => (
            <Table.HeaderCell key={col.id} columnId={col.id} className="!tw-h-9 !tw-min-h-9">
              {col.label}
            </Table.HeaderCell>
          ))
        }
      </Table.Header>
      <Table.Row className={classNames("tw-h-auto !tw-min-h-[68px] tw-rounded-b-7")}>
        <Table.Cell columnId="file-name" className="tw-flex tw-flex-col !tw-items-start tw-justify-center !tw-gap-0">
          {props.media.name}
        </Table.Cell>
        <Table.Cell
          columnId="file-duration"
          className="tw-flex tw-flex-col !tw-items-start tw-justify-center !tw-gap-0"
        >
          {length}
        </Table.Cell>
        <Table.Cell
          columnId="spoken-language"
          className="tw-flex tw-flex-col !tw-items-start tw-justify-center !tw-gap-0"
        >
          <FlagAndLanguage languageCode={props.media.languageCode} />
        </Table.Cell>
      </Table.Row>
    </Table>
  );
};
