import React from "react";
import classNames from "classnames";

import { IconButton } from "@shared/primitives/button";
import { Table } from "@shared/primitives/table";
import { TableColumn } from "@shared/primitives/table/table.context";

import { RiArrowRightSLine } from "@remixicon/react";

type SectionTableProps = {
  children: React.ReactNode;
  columns: TableColumn[];
};

export const SectionTable: React.FC<SectionTableProps> = (props) => {
  return (
    <Table className="tw-flex tw-w-full tw-overflow-auto" columns={props.columns} items={[]}>
      <Table.Header className="tw--mb-px !tw-h-12 !tw-min-h-12 tw-overflow-hidden tw-whitespace-nowrap tw-rounded-t-7 tw-border-b tw-border-neutral-200 tw-bg-neutral-50">
        {(cols) =>
          cols.map((col) => (
            <Table.HeaderCell key={col.id} columnId={col.id}>
              {col.label}
            </Table.HeaderCell>
          ))
        }
      </Table.Header>
      {props.children}
    </Table>
  );
};

interface SectionTableRowProps {
  className?: string;
  withSummary?: boolean;
  disableSummaryButton?: boolean;
  children?: React.ReactNode;
  cells: { id: string; value: string | React.ReactNode }[];
}

export const SectionTableRow: React.FC<SectionTableRowProps> = (props) => {
  const [isOpen, setOpen] = React.useState(false);

  const toggleSummary = () => {
    if (!props.disableSummaryButton) {
      setOpen(!isOpen);
    }
  };

  return (
    <>
      <Table.Row className={classNames("!tw-h-auto !tw-min-h-[68px]", props.className)}>
        {props.cells.map((cell, index) => (
          <Table.Cell
            key={cell.id}
            columnId={cell.id}
            onClick={index === 0 && props.withSummary ? toggleSummary : undefined}
            className={index === 0 && props.withSummary && !props.disableSummaryButton ? "tw-cursor-pointer" : ""}
          >
            {index === 0 && props.withSummary && (
              <IconButton
                className="tw-mr-2 tw-border tw-border-black"
                corners="pill"
                icon={<RiArrowRightSLine className={classNames("tw-transition-all", { "tw-rotate-90": isOpen })} />}
                onClick={(event) => {
                  event.stopPropagation();
                  toggleSummary();
                }}
                size="32"
                variant="secondary"
                disabled={props.disableSummaryButton}
              />
            )}
            {cell.value}
          </Table.Cell>
        ))}
      </Table.Row>
      {props.withSummary && (
        <Table.Summary visible={isOpen}>
          <div className="tw-bg-neutral-50 tw-p-2">{props.children}</div>
        </Table.Summary>
      )}
    </>
  );
};
