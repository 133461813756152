import React from "react";
import { useNavigate } from "react-router-dom";

import { getAccessibilityReport, startAccessibilityAnalysis } from "@frontend/api/media.service";
import { SETTINGS_BILLING_PATH } from "@frontend/routes";

import { useModal } from "@shared/hooks/use-modal";
import { usePlan } from "@shared/hooks/use-plan";
import { EnrichedMediaListItem } from "@shared/interfaces/media";
import { ModalType } from "@shared/interfaces/modal-type";
import {
  accessibilityAnalyserQueueStore,
  AnalysingStatus
} from "@shared/state/accessibility-analysing-queue/accessibility-analyser-queue.store";
import { getCreditMinutes } from "@shared/utils/plans";

import { CombinedReport, WCAGLevel } from "@getsubly/common";

import { AlertModal } from "../alert";
import { Confirm } from "../confirm";

import { AccessibilityReportDetailedViewModal } from "./views/detailed-view";
import { AccessibilityReportLoadingViewModal } from "./views/loading-view";
import { AccessibilityReportSplashViewModal } from "./views/splash-view";
import { AccessibilityReportSummaryViewModal } from "./views/summary-view";
import { getCost } from "./pricing";
import { sampleReport } from "./sample-report";

export enum AccessibilityFramework {
  WCAG = "WCAG"
}

export enum AccessibilityStandardId {
  WCAG_2_1 = "wcag-2.1"
}

export type AccessibilityComplianceStandard = {
  id: AccessibilityStandardId;
  label: string;
  framework: AccessibilityFramework;
  level?: WCAGLevel;
};

interface AccessibilityReportModalProps {
  onDismiss: () => void;
  media: EnrichedMediaListItem;
}

export const AccessibilityReportModal: React.FC<AccessibilityReportModalProps> = (props) => {
  const [isLoading, setLoading] = React.useState(true);
  const [accessibilityReport, setAccessibilityReport] = React.useState<CombinedReport | undefined>();
  const [analysedAt, setAnalysedAt] = React.useState<null | string>(null);
  const [advancedView, setAdvancedView] = React.useState<boolean>(false);
  const [isViewingSampleReport, setViewingSampleReport] = React.useState<boolean>(false);
  const { isEnterprise, isTrial, availableSeconds } = usePlan();
  const navigate = useNavigate();

  const [standards, setStandards] = React.useState<AccessibilityComplianceStandard[]>([]);

  React.useEffect(() => {
    if (isTrial) return setLoading(false);

    getAccessibilityReport(props.media.mediaId)
      .then((result) => {
        if (result?.status === "analysing" || result?.status === "complete") {
          setAccessibilityReport({
            ...result.combinedReport!
          });

          setStandards([
            {
              id: AccessibilityStandardId.WCAG_2_1,
              label: "WCAG 2.1",
              framework: AccessibilityFramework.WCAG,
              level: result.combinedReport!.WCAGOverallCompliance
            }
          ]);

          setAnalysedAt(result.analysedAt);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  // loading state
  if (isLoading) {
    return <AccessibilityReportLoadingViewModal media={props.media} onDismiss={props.onDismiss} />;
  }

  const [showConfirmPrompt, hideConfirmPrompt] = useModal(ModalType.Confirm);
  const [showAlertPrompt, hideAlertPrompt] = useModal(ModalType.Alert);

  const handleClickAnalyse = async () => {
    const isReAnalysing = Boolean(props.media.accessibilityReport?.analysedAt);

    const startAnalyse = () => {
      startAccessibilityAnalysis(props.media.mediaId);
      accessibilityAnalyserQueueStore.addItemToQueue({
        analysingStatus: AnalysingStatus.Analysing,
        mediaId: props.media.mediaId,
        media: props.media
      });
      accessibilityAnalyserQueueStore.setShow(true);
      props.onDismiss();
    };

    if (isReAnalysing) {
      const mediaDurationSeconds = props.media.duration || 0;
      const minutesLeft = getCreditMinutes(availableSeconds);
      const cost = getCost({
        isEnterprise,
        mediaDurationSeconds
      });
      if (cost > minutesLeft) {
        showAlertPrompt(
          <AlertModal
            onCancel={hideAlertPrompt}
            onConfirm={() => {
              hideAlertPrompt();
              props.onDismiss();
              navigate(SETTINGS_BILLING_PATH);
            }}
            title="You need more minutes to run this analysis"
            description={`This analysis requires ${cost} minutes. Your account has ${minutesLeft} minutes remaining.`}
            confirmButtonText="Upgrade Plan"
            cancelButtonText="Close"
            isVerticallyCentred
          />
        );
      } else {
        showConfirmPrompt(
          <Confirm
            onCancel={hideConfirmPrompt}
            onConfirm={startAnalyse}
            confirmButtonText="Analyse"
            title="Are you sure?"
            description={`This will cost you ${cost} minutes. You have ${minutesLeft} minutes left.`}
            isVerticallyCentred
          />
        );
      }
    } else {
      startAnalyse();
    }
  };

  const handleClickViewSampleData = async () => {
    setLoading(true);
    setTimeout(() => {
      setAccessibilityReport(sampleReport);
      setAnalysedAt(new Date().toUTCString());
      setViewingSampleReport(true);
      setStandards([
        {
          id: AccessibilityStandardId.WCAG_2_1,
          label: "WCAG 2.1",
          framework: AccessibilityFramework.WCAG,
          level: WCAGLevel.FAIL
        }
      ]);
      setLoading(false);
    }, 600);
  };

  // loaded but not yet analysed
  if (!analysedAt || (isTrial && !isViewingSampleReport)) {
    return (
      <AccessibilityReportSplashViewModal
        media={props.media}
        onClickAnalyse={handleClickAnalyse}
        onDismiss={props.onDismiss}
        onClickViewSampleData={handleClickViewSampleData}
        onClickTalkToUs={() => alert("talk to us")}
      />
    );
  }

  // Report: advanced view
  if (advancedView) {
    return (
      <AccessibilityReportDetailedViewModal
        media={props.media}
        report={accessibilityReport!}
        analysedAt={analysedAt}
        onDismiss={props.onDismiss}
        onClickAnalyse={handleClickAnalyse}
        onClickSummaryView={() => setAdvancedView(false)}
        disableReAnalyse={isViewingSampleReport}
      />
    );
  }

  // Report: summary view
  return (
    <AccessibilityReportSummaryViewModal
      onDismiss={props.onDismiss}
      onClickAnalyse={handleClickAnalyse}
      onClickDetailedView={() => setAdvancedView(true)}
      media={props.media}
      analysedAt={analysedAt}
      complianceStandards={standards}
      disableReAnalyse={isViewingSampleReport}
    />
  );
};
